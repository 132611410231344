import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'

export const FloorsSchema = z.object({
  removeOld: RoomsSchema,
  recycleWoodenFloor: RoomsSchema,
  newSkirtingBoards: RoomsSchema,
  recycleSkirtingBoards: RoomsSchema
})

export default FloorsSchema
