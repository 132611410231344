<template>
  <div class="" v-if="iconSvg" v-html="iconSvg"></div>
</template>

<script>
import { ref, onMounted, watch, createApp } from 'vue'
// Import your SVG icons
import Loop from '@/components/Icons/Loop.vue'
import Bathroom from '@/components/Icons/Services/Bathroom.vue'
import Blueprint from '@/components/Icons/Services/Blueprint.vue'
import Doors from '@/components/Icons/Services/Doors.vue'
import Electrical from '@/components/Icons/Services/Electrical.vue'
import Facade from '@/components/Icons/Services/Facade.vue'
import Floor from '@/components/Icons/Services/Floor.vue'
import Heating from '@/components/Icons/Services/Heating.vue'
import Pipes from '@/components/Icons/Services/Pipes.vue'
import Roof from '@/components/Icons/Services/Roof.vue'
import Stairs from '@/components/Icons/Services/Stairs.vue'
import Walls from '@/components/Icons/Services/Walls.vue'
import Windows from '@/components/Icons/Services/Windows.vue'
import Contact from '@/components/Icons/Contact.vue'

import { renderToString } from '@vue/server-renderer'

export default {
  props: {
    iconName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const iconSvg = ref(null)

    const renderSVG = async (Component) => {
      const app = createApp(Component)
      const html = await renderToString(app)
      app.unmount()
      return html
    }

    const iconMap = {
      loop: Loop,
      bathroom: Bathroom,
      blueprint: Blueprint,
      doors: Doors,
      electrical: Electrical,
      facade: Facade,
      floors: Floor,
      heating: Heating,
      pipes: Pipes,
      roof: Roof,
      stairs: Stairs,
      wallsCeilings: Walls,
      windows: Windows,
      contact: Contact
    }

    onMounted(async () => {
      if (props.iconName && iconMap[props.iconName]) {
        iconSvg.value = await renderSVG(iconMap[props.iconName])
      }
    })

    watch(
      () => props.iconName,
      async (newIconName) => {
        if (newIconName && iconMap[newIconName]) {
          iconSvg.value = await renderSVG(iconMap[newIconName])
        } else {
          iconSvg.value = null
        }
      }
    )

    return {
      iconSvg
    }
  }
}
</script>
