<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    class="h-[1em] text-current"
  >
    <defs>
      <clipPath id="4b07606c6a">
        <path
          d="M 0 67.46875 L 375 67.46875 L 375 259.46875 L 0 259.46875 Z M 0 67.46875 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#4b07606c6a)">
      <path
        fill="currentColor"
        d="M 373.761719 248.804688 L 305.117188 108.085938 C 303.851562 105.496094 301.222656 103.851562 298.339844 103.851562 L 266.753906 103.851562 L 266.753906 75.007812 C 266.753906 70.84375 263.375 67.46875 259.210938 67.46875 L 207.730469 67.46875 C 203.566406 67.46875 200.1875 70.84375 200.1875 75.007812 L 200.1875 103.851562 L 78.679688 103.851562 C 78.332031 103.851562 77.988281 103.878906 77.652344 103.925781 C 74.511719 103.488281 71.308594 105.078125 69.84375 108.085938 L 1.199219 248.804688 C 0.0585938 251.144531 0.203125 253.902344 1.582031 256.105469 C 2.957031 258.3125 5.375 259.652344 7.972656 259.652344 L 34.746094 259.652344 C 37.628906 259.652344 40.257812 258.007812 41.523438 255.417969 L 91.035156 153.917969 L 140.546875 255.417969 C 141.808594 258.007812 144.441406 259.652344 147.320312 259.652344 L 366.984375 259.652344 C 369.582031 259.652344 372 258.3125 373.378906 256.105469 C 374.757812 253.902344 374.898438 251.144531 373.761719 248.804688 Z M 215.269531 82.550781 L 251.671875 82.550781 L 251.671875 103.851562 L 215.269531 103.851562 Z M 30.035156 244.570312 L 20.042969 244.570312 L 77.648438 126.476562 L 82.644531 136.71875 Z M 152.035156 244.570312 L 90.746094 118.929688 L 293.628906 118.929688 L 354.917969 244.570312 Z M 152.035156 244.570312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
    <path
      stroke-linecap="butt"
      transform="matrix(0.53033, 0.53033, -0.53033, 0.53033, 207.128326, 113.241021)"
      fill="none"
      stroke-linejoin="miter"
      d="M 0.00164642 0.50142 L 2.189258 0.50142 "
      stroke="#001932"
      stroke-width="1"
      stroke-opacity="1"
      stroke-miterlimit="4"
    />
  </svg>
</template>
