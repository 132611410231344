<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    class="h-[1em] text-current"
  >
    <defs>
      <clipPath id="d9b18f3d49">
        <path
          d="M 17.578125 0 L 357.328125 0 L 357.328125 173 L 17.578125 173 Z M 17.578125 0 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <path
      fill="currentColor"
      d="M 311.832031 375.003906 L 63.164062 375.003906 C 60.410156 375.003906 58.175781 372.765625 58.175781 370.011719 L 58.175781 156.363281 C 58.175781 153.609375 60.410156 151.375 63.164062 151.375 C 65.921875 151.375 68.15625 153.609375 68.15625 156.363281 L 68.15625 365.023438 L 306.84375 365.023438 L 306.84375 156.363281 C 306.84375 153.609375 309.078125 151.375 311.832031 151.375 C 314.589844 151.375 316.824219 153.609375 316.824219 156.363281 L 316.824219 370.011719 C 316.824219 372.765625 314.589844 375.003906 311.832031 375.003906 Z M 311.832031 375.003906 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <g clip-path="url(#d9b18f3d49)">
      <path
        fill="currentColor"
        d="M 332.488281 172.234375 C 325.933594 172.234375 319.753906 169.972656 315.089844 165.875 L 187.496094 53.683594 L 59.910156 165.875 C 55.246094 169.972656 49.0625 172.234375 42.503906 172.234375 C 35.945312 172.234375 29.765625 169.972656 25.105469 165.875 C 20.261719 161.609375 17.589844 155.875 17.589844 149.714844 C 17.589844 143.5625 20.257812 137.828125 25.105469 133.5625 L 169.710938 6.40625 C 174.402344 2.28125 180.71875 0.0078125 187.496094 0.0078125 C 194.277344 0.0078125 200.59375 2.28125 205.28125 6.40625 L 349.890625 133.5625 C 354.734375 137.824219 357.402344 143.558594 357.402344 149.714844 C 357.402344 155.871094 354.734375 161.609375 349.890625 165.871094 C 345.226562 169.96875 339.046875 172.230469 332.488281 172.230469 Z M 187.496094 42.042969 C 188.671875 42.042969 189.851562 42.460938 190.792969 43.285156 L 321.675781 158.378906 C 324.515625 160.875 328.355469 162.253906 332.488281 162.253906 C 336.625 162.253906 340.460938 160.875 343.304688 158.378906 C 345.964844 156.039062 347.429688 152.964844 347.429688 149.714844 C 347.429688 146.46875 345.964844 143.398438 343.304688 141.058594 L 198.699219 13.90625 C 195.871094 11.417969 191.792969 9.992188 187.5 9.992188 C 183.210938 9.992188 179.128906 11.417969 176.300781 13.90625 L 31.699219 141.0625 C 29.039062 143.402344 27.574219 146.476562 27.574219 149.722656 C 27.574219 152.972656 29.039062 156.042969 31.699219 158.382812 C 34.542969 160.882812 38.378906 162.257812 42.511719 162.257812 C 46.644531 162.257812 50.484375 160.882812 53.324219 158.382812 L 184.207031 43.289062 C 185.148438 42.460938 186.320312 42.042969 187.5 42.042969 Z M 187.496094 42.042969 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
    </g>
    <path
      fill="currentColor"
      d="M 231.160156 169.621094 L 161.886719 169.621094 C 149.628906 169.621094 139.660156 159.648438 139.660156 147.390625 C 139.660156 135.136719 149.628906 125.164062 161.886719 125.164062 L 231.160156 125.164062 C 243.410156 125.164062 253.382812 135.136719 253.382812 147.390625 C 253.382812 159.652344 243.410156 169.621094 231.160156 169.621094 Z M 161.886719 135.148438 C 155.132812 135.148438 149.636719 140.640625 149.636719 147.390625 C 149.636719 154.144531 155.128906 159.640625 161.886719 159.640625 L 231.15625 159.640625 C 237.90625 159.640625 243.402344 154.144531 243.402344 147.394531 C 243.402344 140.640625 237.90625 135.144531 231.15625 135.144531 Z M 161.886719 135.148438 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 215.195312 257.722656 C 212.441406 257.722656 210.207031 255.484375 210.207031 252.730469 L 210.207031 211.875 C 210.207031 209.898438 211.371094 208.109375 213.171875 207.3125 L 263.511719 185.007812 L 263.511719 152.382812 L 248.390625 152.382812 C 245.636719 152.382812 243.402344 150.148438 243.402344 147.394531 C 243.402344 144.636719 245.636719 142.402344 248.390625 142.402344 L 268.503906 142.402344 C 271.261719 142.402344 273.496094 144.636719 273.496094 147.394531 L 273.496094 188.25 C 273.496094 190.226562 272.332031 192.015625 270.527344 192.8125 L 220.1875 215.117188 L 220.1875 252.730469 C 220.183594 255.484375 217.949219 257.722656 215.195312 257.722656 Z M 215.195312 257.722656 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 231.476562 322.203125 L 198.914062 322.203125 C 196.160156 322.203125 193.925781 319.96875 193.925781 317.210938 L 193.925781 252.730469 C 193.925781 249.976562 196.160156 247.742188 198.914062 247.742188 L 231.476562 247.742188 C 234.230469 247.742188 236.464844 249.976562 236.464844 252.730469 L 236.464844 317.214844 C 236.464844 319.96875 234.230469 322.203125 231.476562 322.203125 Z M 203.90625 312.222656 L 226.488281 312.222656 L 226.488281 257.71875 L 203.90625 257.71875 Z M 203.90625 312.222656 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 150.621094 354.113281 L 138.9375 354.113281 C 129.316406 354.113281 121.488281 346.28125 121.488281 336.660156 L 121.488281 254.871094 C 121.488281 252.117188 123.722656 249.878906 126.476562 249.878906 C 129.234375 249.878906 131.46875 252.117188 131.46875 254.871094 L 131.46875 336.660156 C 131.46875 340.777344 134.820312 344.132812 138.9375 344.132812 L 150.621094 344.132812 C 154.738281 344.132812 158.089844 340.78125 158.089844 336.660156 L 158.089844 254.867188 C 158.089844 252.113281 160.324219 249.878906 163.082031 249.878906 C 165.835938 249.878906 168.070312 252.113281 168.070312 254.867188 L 168.070312 336.660156 C 168.074219 346.285156 160.242188 354.113281 150.621094 354.113281 Z M 150.621094 354.113281 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 144.78125 264.507812 C 120.914062 264.507812 101.503906 245.09375 101.503906 221.230469 C 101.503906 203.523438 112.097656 187.78125 128.496094 181.121094 C 130.042969 180.5 131.785156 180.679688 133.160156 181.605469 C 134.539062 182.53125 135.363281 184.082031 135.363281 185.746094 L 135.363281 214.601562 L 154.195312 214.605469 L 154.195312 185.746094 C 154.195312 184.085938 155.023438 182.53125 156.402344 181.605469 C 157.78125 180.679688 159.53125 180.5 161.0625 181.125 C 177.460938 187.785156 188.054688 203.53125 188.054688 221.230469 C 188.058594 245.097656 168.640625 264.511719 144.777344 264.511719 Z M 125.386719 194.140625 C 116.757812 200.292969 111.484375 210.277344 111.484375 221.230469 C 111.484375 239.59375 126.417969 254.53125 144.777344 254.53125 C 163.140625 254.53125 178.074219 239.59375 178.074219 221.230469 C 178.074219 210.277344 172.800781 200.296875 164.175781 194.144531 L 164.175781 219.59375 C 164.175781 222.347656 161.941406 224.582031 159.183594 224.582031 L 130.371094 224.582031 C 127.617188 224.582031 125.378906 222.347656 125.378906 219.59375 L 125.378906 194.140625 Z M 125.386719 194.140625 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      stroke-linecap="butt"
      transform="matrix(0.53033, 0.53033, -0.53033, 0.53033, 205.951558, 128.539136)"
      fill="none"
      stroke-linejoin="miter"
      d="M 0.00140758 0.49932 L 2.189019 0.49932 "
      stroke="#001932"
      stroke-width="1"
      stroke-opacity="1"
      stroke-miterlimit="4"
    />
  </svg>
</template>
