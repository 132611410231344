import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'
import AmountSchema from '@/data/validations/types/amount.js'

const OpenCloseSchema = z
  .object({
    enabled: z.boolean(),
    size: z.number().optional(),
    type: z.string().optional()
  })
  .refine(
    (value) => {
      return !(value.enabled && (!value.amount || !value.type))
    },
    {
      message: 'amount and type is required when enabled is true'
    }
  )
export const BlueprintSchema = z.object({
  open: OpenCloseSchema,
  close: OpenCloseSchema,
  postProcessing: z.object({
    enabled: z.boolean()
  })
})

export default BlueprintSchema
