import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'
import AmountSchema from '@/data/validations/types/amount.js'
import TypeSchema from '@/data/validations/types/type.js'

// @todo improve schema. Currently it's just a placeholder
export const RoofSchema = z.object({
  change: TypeSchema
})

export default RoofSchema
