<script setup>
import { computed, onMounted } from 'vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'

const emit = defineEmits(['update:modelValue', 'change'])

const props = defineProps({
  modelValue: {},
  options: Array
})

const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})

const emitChange = (value, type) => {
  emit('change', {
    value,
    type
  })
}
</script>

<template>
  <div class="grid grid-cols-2 gap-2 md:grid-cols-3">
    <div v-for="(option, k) in options" :key="k" class="flex flex-col items-center gap-2">
      <div class="w-full">
        <div class="relative rounded-lg border-2 border-primary">
          <div class="relative flex flex-col items-center">
            <component
              :alt="option.label"
              :is="option.image"
              class="h-20 w-auto object-contain object-center sm:h-20 lg:h-36"
            />
            <label
              class="mt-3 block w-full truncate text-center text-lg font-semibold leading-[112%]"
            >
              {{ option.label }}
            </label>
          </div>
        </div>
      </div>
      <div>
        <CounterInput
          v-model="model[option.value]"
          @update:model-value="(v) => emitChange(v, option.value)"
        />
      </div>
    </div>
  </div>
</template>
