<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    class="h-[1em] text-current"
  >
    <defs>
      <clipPath id="63c2b14bdb">
        <path
          d="M 0 47.855469 L 375 47.855469 L 375 313.355469 L 0 313.355469 Z M 0 47.855469 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <path
      stroke-linecap="butt"
      transform="matrix(0.53033, 0.53033, -0.53033, 0.53033, 158.488163, 145.194586)"
      fill="none"
      stroke-linejoin="miter"
      d="M 0.000796855 0.501441 L 2.188409 0.501441 "
      stroke="#001932"
      stroke-width="1"
      stroke-opacity="1"
      stroke-miterlimit="4"
    />
    <g clip-path="url(#63c2b14bdb)">
      <path
        fill="currentColor"
        d="M 371.003906 189.421875 L 278.628906 133.988281 L 278.613281 133.988281 L 264.207031 125.34375 L 277.535156 116.851562 C 279.78125 115.417969 281.140625 112.933594 281.140625 110.265625 C 281.140625 107.597656 279.78125 105.117188 277.535156 103.679688 L 191.699219 49.058594 C 189.140625 47.433594 185.875 47.433594 183.316406 49.058594 L 97.480469 103.679688 C 95.234375 105.132812 93.875 107.613281 93.875 110.28125 C 93.875 112.949219 95.234375 115.433594 97.480469 116.867188 L 110.808594 125.359375 L 96.402344 134.003906 L 96.386719 134.003906 L 4.011719 189.4375 C 1.671875 190.84375 0.234375 193.371094 0.21875 196.101562 C 0.21875 198.835938 1.625 201.363281 3.964844 202.796875 L 183.441406 312.042969 C 184.691406 312.792969 186.09375 313.183594 187.5 313.183594 C 188.90625 313.183594 190.308594 312.808594 191.558594 312.042969 L 371.035156 202.796875 C 373.375 201.378906 374.796875 198.835938 374.78125 196.101562 C 374.796875 193.355469 373.34375 190.828125 371.003906 189.421875 Z M 99.664062 242.765625 L 23.117188 196.164062 L 100.289062 149.859375 L 172.96875 196.117188 Z M 187.53125 186.863281 L 115.273438 140.886719 L 125.558594 134.722656 L 183.316406 171.492188 C 184.597656 172.304688 186.046875 172.707031 187.515625 172.707031 C 188.984375 172.707031 190.433594 172.304688 191.714844 171.492188 L 249.503906 134.722656 L 259.789062 140.886719 Z M 275.351562 242.765625 L 202.046875 196.117188 L 274.726562 149.859375 L 351.898438 196.164062 Z M 275.351562 242.765625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script setup lang="ts"></script>
