import Yes from '@public/images/yes.svg'
import No from '@public/images/no.svg'
import BudgetQuality from '@public/images/calc/quality/1_b.svg'
import StandardQuality from '@public/images/calc/quality/2_b.svg'
import PremiumQuality from '@public/images/calc/quality/3_b.svg'

export const yesNo = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const qualities = [
  {
    label: 'Budget - Qualität',
    image: BudgetQuality,
    value: 'budget'
  },
  {
    label: 'Standard - Qualität',
    image: StandardQuality,
    value: 'standard'
  },
  {
    label: 'Premium - Qualität',
    image: PremiumQuality,
    value: 'premium'
  }
]
