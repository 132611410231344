<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue', 'next'])

const props = defineProps({
  modelValue: {},
  name: {
    type: String,
    default: ''
  },
  options: Array
})

const setActive = (option) => {
  model.value = option.value
}

const isActive = (value) => {
  return model.value == value
}

const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
    emit('next')
  }
})
</script>
<template>
  <fieldset class="grid grid-cols-3 gap-4 md:grid-cols-1" :name="name">
    <div v-for="(option, k) in options" :key="k" class="relative flex flex-col items-center">
      <div class="w-full">
        <button
          class="block w-full rounded-lg border border-primary hover:bg-primary-500/30"
          type="button"
          :class="{
            'bg-primary-500/20': isActive(option.value)
          }"
          @click="() => setActive(option)"
        >
          <div class="relative flex flex-row items-center p-1">
            <component
              :alt="option.label"
              :is="option.image"
              class="hidden h-6 w-10 rounded-[13px] object-contain object-center sm:block sm:h-8 lg:h-10"
            />
            <label
              class="text-md pointer-events-none block w-full text-center font-semibold leading-[112%] sm:block"
            >
              {{ option.label }}
            </label>
          </div>
        </button>
      </div>
    </div>
  </fieldset>
</template>
