import Yes from '@public/images/yes.svg'
import No from '@public/images/no.svg'
import Massive from '@public/images/calc/blueprint/v2/massive.svg'
import Light from '@public/images/calc/blueprint/v2/light.svg'

export const yesNo = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const wallType = [
  {
    label: 'Massivwand',
    value: 'massive',
    image: Massive
  },
  {
    label: 'Leichtwand',
    value: 'light',
    image: Light
  }
]
