<script setup>
import { nextTick, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {},
  type: {
    type: String,
    default: 'text' // Changed from 'number' to 'text'
  },
  name: {
    type: String,
    default: ''
  },
  tabIndex: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: Infinity
  },
  min: {
    type: Number,
    default: -Infinity
  }
})

const emit = defineEmits(['update:modelValue'])

const formatNumber = (value) => {
  return (value * 1).toFixed(2).replace('.', ',')
}

const parseNumber = (value) => {
  return parseFloat((value + '').replace(',', '.'))
}

const v = ref(formatNumber(props.modelValue))

const formatAndClampNumber = (inputValue) => {
  let value = parseNumber(inputValue)
  if (isNaN(value)) {
    value = props.min
  }
  value = Math.min(Math.max(value, props.min), props.max)
  return formatNumber(value)
}

const updateValue = () => {
  const formattedValue = formatAndClampNumber(v.value)
  v.value = formattedValue
  emit('update:modelValue', parseNumber(formattedValue))
}

const handleInput = (event) => {
  const input = event.target
  const cursorPosition = input.selectionStart
  const oldValue = v.value
  const newValue = input.value

  // Allow only digits, comma, and minus sign
  const sanitizedValue = newValue.replace(/[^0-9,-]/g, '')

  v.value = sanitizedValue

  // Restore cursor position
  nextTick(() => {
    // Only set selection range if the input is still focused
    if (document.activeElement === input) {
      input.setSelectionRange(cursorPosition, cursorPosition)
    }
  })
}

const selectAllText = (event) => {
  event.target.select()
}

// Watch for changes in the modelValue prop
watch(
  () => props.modelValue,
  (newValue) => {
    v.value = formatNumber(newValue)
  }
)
</script>

<template>
  <input
    v-model="v"
    :name="name"
    :type="type"
    class="input no-spinners"
    :tabindex="tabIndex"
    @blur="updateValue"
    @focus="selectAllText"
    @keyup.enter="updateValue"
    @input="handleInput"
  />
</template>
