import { z } from 'zod'
import AmountSchema from '@/data/validations/types/amount.js'
import TypeSchema from '@/data/validations/types/type.js'

export const WindowsSchema = z.object({
  renewReveals: z.object({
    enabled: z.boolean()
  }),
  renewSills: TypeSchema,
  lack: AmountSchema
})

export default WindowsSchema
