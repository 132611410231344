<template xmlns="http://www.w3.org/1999/html">
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_blueprint_start'">
        <Headline>Sollen Wände geöffnet werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNo"
          v-model="model.services.blueprint.open.enabled"
        />
        <SingleNavigation
          v-if="!model.services.blueprint.open.enabled"
          step="services_blueprint_close"
          v-model="model"
        />
        <SingleNavigation v-else step="services_blueprint_open_size" v-model="model" />
      </template>

      <template v-if="model.step === 'services_blueprint_open_size'">
        <Headline>Wie viel Quadratmeter Wand soll geöffnet werden?</Headline>
        <Range v-model="model.services.blueprint.open.size" :max="100" />
        <SingleNavigation
          step="services_blueprint_wall_type"
          prev="services_blueprint_start"
          v-model="model"
        />
      </template>

      <template v-if="model.step === 'services_blueprint_wall_type'">
        <Headline>Was für eine Wand soll geöffnet werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="wallType"
          v-model="model.services.blueprint.open.type"
        />
        <SingleNavigation
          step="services_blueprint_close"
          prev="services_blueprint_open_size"
          v-model="model"
          :disabled="!model.services.blueprint.open.type"
        />
      </template>

      <template v-if="model.step === 'services_blueprint_close'">
        <Headline>Sollen Wände verschlossen werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNo"
          v-model="model.services.blueprint.close.enabled"
        />
        <SingleNavigation
          v-if="!model.services.blueprint.open.enabled && model.services.blueprint.close.enabled"
          step="services_blueprint_close_size"
          prev="services_blueprint_start"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="
            model.services.blueprint.open.enabled && model.services.blueprint.close.enabled
          "
          step="services_blueprint_close_size"
          prev="services_blueprint_wall_type"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="
            model.services.blueprint.open.enabled && !model.services.blueprint.close.enabled
          "
          step="services_blueprint_postProcessing"
          prev="services_blueprint_wall_type"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="
            !model.services.blueprint.open.enabled && !model.services.blueprint.close.enabled
          "
          step="services_blueprint_postProcessing"
          prev="services_blueprint_start"
          v-model="model"
        />
      </template>
      <template v-if="model.step === 'services_blueprint_close_size'">
        <Headline>Wie viel Quadratmeter Wand soll verschlossen werden?</Headline>
        <Range v-model="model.services.blueprint.close.size" :max="50" />
        <SingleNavigation
          step="services_blueprint_close_wall_type"
          prev="services_blueprint_close"
          v-model="model"
          :disabled="!model.services.blueprint.close.size"
        />
      </template>

      <template v-if="model.step === 'services_blueprint_close_wall_type'">
        <Headline>Mit welchem Material sollen die Wände verschlossen werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="wallType"
          v-model="model.services.blueprint.close.type"
        />
        <SingleNavigation
          step="services_blueprint_postProcessing"
          prev="services_blueprint_close_size"
          v-model="model"
          :disabled="!model.services.blueprint.close.type"
        />
      </template>
      <template v-if="model.step === 'services_blueprint_postProcessing'">
        <Headline>Sollen die Öffnungen oder Schließungen nachbearbeitet werden?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="yesNo"
          v-model="model.services.blueprint.postProcessing.enabled"
        />
        <SingleNavigation
          v-if="!model.services.blueprint.open.enabled && !model.services.blueprint.close.enabled"
          step="services_blueprint_final"
          prev="services_blueprint_start"
          v-model="model"
        />
        <SingleNavigation
          v-if="model.services.blueprint.open.enabled && !model.services.blueprint.close.enabled"
          step="services_blueprint_final"
          prev="services_blueprint_close"
          v-model="model"
        />
        <SingleNavigation
          v-if="!model.services.blueprint.open.enabled && model.services.blueprint.close.enabled"
          step="services_blueprint_final"
          prev="services_blueprint_close_wall_type"
          v-model="model"
        />
        <SingleNavigation
          v-if="model.services.blueprint.open.enabled && model.services.blueprint.close.enabled"
          step="services_blueprint_final"
          prev="services_blueprint_close_wall_type"
          v-model="model"
        />
      </template>
    </form>
  </section>
</template>

<script setup>
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import { computed, onMounted } from 'vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import Range from '@/components/Inputs/Singles/Range.vue'
import { wallType, yesNo } from '@/data/options/v2/blueprint.js'
import track from '@/utils/tracking.js'
import { ContactValidationSchema } from '@/utils/validators.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case '"services_blueprint_start"':
      return 0
    case 'services_blueprint_open_size':
      return 10
    case 'services_blueprint_wall_type':
      return 20
    case 'services_blueprint_close':
      return 40
    case 'services_blueprint_close_size':
      return 60
    case 'services_blueprint_close_wall_type':
      return 70
    case 'services_blueprint_postProcessing':
      return 90
    case 'services_blueprint_final':
      return 100
    default:
      return 0
  }
})

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'blueprint',
              content_name: 'blueprint'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('blueprint', data.volume / 100, 'EUR', data.id, model.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}

onMounted(() => {
  model.value.step = 'services_blueprint_start'
})
</script>
