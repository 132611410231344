import Saddle from '@public/images/calc/roof/v2/saddle.svg'
import Flat from '@public/images/calc/roof/v2/flat.svg'
import Yes from '@public/images/yes.svg'
import No from '@public/images/no.svg'
import NewRoof from '@public/images/calc/roof/v2/new_roof.svg'
import InsulationOnly from '@public/images/calc/roof/v2/insulation_only.svg'
import RoofInsulation from '@public/images/calc/roof/v2/roof_insulation.svg'
import ClayRoofTile from '@public/images/calc/roof/v2/clay_roof_tile.svg'
import Bitum from '@public/images/calc/roof/v2/bitum.svg'
import RoofFoil from '@public/images/calc/roof/v2/roof_foil.svg'

export const roofFormsOptions = [
  { label: 'Satteldach', value: 'saddle', image: Saddle },
  { label: 'Flachdach', value: 'flat', image: Flat }
]

export const yesNo = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const startType = [
  {
    label: 'Neues Dach inkl. Dämmung',
    image: NewRoof,
    value: 'renew'
  },
  {
    label: 'Nur Dämmung',
    image: InsulationOnly,
    value: 'insulation'
  }
]

export const currentPavingSaddle = [
  {
    label: 'Betondachziegel',
    image: RoofInsulation,
    value: 'concreteTile'
  },
  {
    label: 'Tondachpfanne',
    image: ClayRoofTile,
    value: 'clayTile'
  }
]

export const currentPavingFlat = [
  {
    label: 'Bitum',
    image: Bitum,
    value: 'bitumen'
  },
  {
    label: 'Dachfolie',
    image: RoofFoil,
    value: 'film'
  }
]
