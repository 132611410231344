const uniqueId = () => {
  return Date.now() + Math.random().toString(36).substr(2, 9)
}

const createRoom = (name, type, edit = false, enabled = false, length = 0, width = 0) => {
  return {
    id: uniqueId(),
    type: type,
    shape: 'square',
    name: name,
    edit,
    enabled,
    height: 2.5,
    length,
    width,
    corner: {
      length: 0,
      width: 0
    },
    areas: {
      floor: 0,
      walls: 0,
      ceiling: 0,
      perimeter: 0
    },
    less_qm: 0
  }
}

export default createRoom
