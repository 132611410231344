import Yes from '@public/images/yes.svg'
import No from '@public/images/no.svg'
import BudgetQuality from '@public/images/calc/quality/1_b.svg'
import StandardQuality from '@public/images/calc/quality/2_b.svg'
import PremiumQuality from '@public/images/calc/quality/3_b.svg'
import StraightStairs from '@public/images/calc/stairs/v2/straight.svg'
import QuarterTurnedStairs from '@public/images/calc/stairs/v2/quarter_turned.svg'
import HalfTurnedStairs from '@public/images/calc/stairs/v2/half_turned.svg'
import TwiceQuarterTurnedStairs from '@public/images/calc/stairs/v2/twice_quarter_turned.svg'

export const yesNoOptions = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const qualities = [
  {
    label: 'Budget',
    image: BudgetQuality,
    value: 'budget'
  },
  {
    label: 'Standard',
    image: StandardQuality,
    value: 'standard'
  },
  {
    label: 'Premium',
    image: PremiumQuality,
    value: 'premium'
  }
]

export const stairs = [
  {
    label: 'Gerade',
    image: StraightStairs,
    value: 'straight'
  },
  {
    label: 'Viertelgewendelt',
    image: QuarterTurnedStairs,
    value: 'quarter_turned'
  },
  {
    label: 'Halbgewendelt',
    image: HalfTurnedStairs,
    value: 'half_turned'
  },
  {
    label: 'Zweimal viertelgewendelt',
    image: TwiceQuarterTurnedStairs,
    value: 'twice_quarter_turned'
  }
]
