import { getImageUrl } from '@/utils/image.js'
import House from '@public/images/calc/baseTypes/house.svg'
import Apartment from '@public/images/calc/baseTypes/apartment.svg'
import Electrical from '@public/images/calc/electrical/electrical.svg'
import Heating from '@public/images/calc/heating/heating.svg'
import Walls from '@public/images/calc/walls/walls.svg'
import Floors from '@public/images/calc/floors/floor.svg'
import Windows from '@public/images/calc/windows/window.svg'
import Doors from '@public/images/calc/doors/doors.svg'
import Bathroom from '@public/images/calc/bathroom/bath.svg'
import Roof from '@public/images/calc/roof/roof.svg'
import Facade from '@public/images/calc/facade/facade.svg'
import Blueprint from '@public/images/calc/blueprint/blueprint.svg'
import Stairs from '@public/images/calc/stairs/stairs.svg'
import Pipes from '@public/images/calc/pipes/pipes.svg'

export const services = [
  {
    label: 'Elektrik',
    image: Electrical,
    value: 'electrical',
    forTypes: ['house', 'apartment']
  },
  {
    label: 'Heizungstechnik',
    image: Heating,
    value: 'heating',
    forTypes: ['apartment', 'house']
  },
  {
    label: 'Wände & Decken',
    image: Walls,
    value: 'wallsCeilings',
    forTypes: ['apartment', 'house']
  },
  {
    label: 'Böden & Fussleisten',
    image: Floors,
    value: 'floors',
    forTypes: ['apartment', 'house']
  },
  {
    label: 'Fenster',
    image: Windows,
    value: 'windows',
    forTypes: ['apartment', 'house']
  },
  {
    label: 'Türen',
    image: Doors,
    value: 'doors',
    forTypes: ['apartment', 'house']
  },
  {
    label: 'Bäder & Gäste WCs',
    image: Bathroom,
    value: 'baths',
    forTypes: ['apartment', 'house']
  },
  {
    label: 'Dach & Dämmung',
    image: Roof,
    value: 'roof',
    forTypes: ['house']
  },
  {
    label: 'Fassade & Dämmung',
    image: Facade,
    value: 'facade',
    forTypes: ['house']
  },
  {
    label: 'Grundrissveränderung',
    image: Blueprint,
    value: 'blueprint',
    forTypes: ['apartment', 'house']
  },
  {
    label: 'Treppe',
    image: Stairs,
    value: 'stairs',
    forTypes: ['apartment', 'house']
  },
  {
    label: 'Versorgungsleitungen',
    image: Pipes,
    value: 'pipes',
    forTypes: ['house']
  }
]

export const houseTypes = [
  {
    label: 'Haus',
    value: 'house',
    image: House
  },
  {
    label: 'Wohnung',
    value: 'apartment',
    image: Apartment
  }
]
