import { z } from 'zod'

export const QualitySchema = z
  .object({
    enabled: z.boolean(),
    quality: z.enum(['budget', 'standard', 'premium']).optional()
  })
  .refine(
    (value) => {
      return !(value.enabled && !value.quality)
    },
    {
      message: 'quality is required when enabled is true'
    }
  )

export default QualitySchema
