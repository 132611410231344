<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    class="h-[1em] text-current"
  >
    <defs>
      <clipPath id="dd4904eb90">
        <path d="M 351 68 L 370.5 68 L 370.5 165 L 351 165 Z M 351 68 " clip-rule="nonzero" />
      </clipPath>
    </defs>
    <path
      stroke-linecap="butt"
      transform="matrix(0.53033, 0.53033, -0.53033, 0.53033, 227.918075, 134.030763)"
      fill="none"
      stroke-linejoin="miter"
      d="M 0.000358662 0.501427 L 2.18797 0.501427 "
      stroke="#001932"
      stroke-width="1"
      stroke-opacity="1"
      stroke-miterlimit="4"
    />
    <path
      fill="currentColor"
      d="M 14.667969 223.953125 L 14.667969 231.671875 C 14.667969 298.828125 69.472656 352.859375 135.855469 352.859375 C 203.007812 352.859375 257.042969 298.828125 257.042969 231.671875 L 257.042969 223.953125 L 189.117188 223.953125 L 189.117188 231.671875 C 189.117188 261.003906 165.1875 285.703125 135.082031 285.703125 C 104.976562 285.703125 81.050781 261.773438 81.050781 231.671875 L 81.050781 223.953125 Z M 14.667969 223.953125 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 274.796875 150.621094 L 331.917969 150.621094 L 331.917969 82.695312 L 274.796875 82.695312 C 228.480469 82.695312 190.660156 119.746094 189.886719 166.058594 L 257.8125 166.058594 C 258.585938 156.796875 266.304688 150.621094 274.796875 150.621094 Z M 274.796875 150.621094 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 0 7.046875 L 96.488281 7.046875 L 96.488281 26.347656 L 0 26.347656 Z M 0 7.046875 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 14.667969 45.644531 L 81.820312 45.644531 L 81.820312 166.058594 L 14.667969 166.058594 Z M 14.667969 45.644531 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 0 185.355469 L 96.488281 185.355469 L 96.488281 204.65625 L 0 204.65625 Z M 0 185.355469 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 175.222656 185.355469 L 271.707031 185.355469 L 271.707031 204.65625 L 175.222656 204.65625 Z M 175.222656 185.355469 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <g clip-path="url(#dd4904eb90)">
      <path
        fill="currentColor"
        d="M 351.214844 68.027344 L 370.511719 68.027344 L 370.511719 164.515625 L 351.214844 164.515625 Z M 351.214844 68.027344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
