import Budget from '@public/images/calc/quality/1_b.svg'
import Standard from '@public/images/calc/quality/2_b.svg'
import Premium from '@public/images/calc/quality/3_b.svg'

export const qualities = [
  {
    label: 'Budget - Qualität',
    image: Budget,
    value: 'budget'
  },
  {
    label: 'Standard - Qualität',
    image: Standard,
    value: 'standard'
  },
  {
    label: 'Premium - Qualität',
    image: Premium,
    value: 'premium'
  }
]
