import Yes from '@public/images/yes.svg'
import No from '@public/images/no.svg'
import Shower from '@public/images/singles/bath/t_shower.svg'
import Bathtub from '@public/images/singles/bath/t_bathtub.svg'
import Toilet from '@public/images/singles/bath/t_toilet.svg'
import Sink from '@public/images/singles/bath/t_sink.svg'
import DoubleSink from '@public/images/singles/bath/t_doublesink.svg'
import BudgetFloor from '@public/images/singles/bath/f_budget.svg'
import StandardFloor from '@public/images/singles/bath/f_standard.svg'
import PremiumFloor from '@public/images/singles/bath/f_premium.svg'
import BudgetQuality from '@public/images/calc/quality/1_b.svg'
import StandardQuality from '@public/images/calc/quality/2_b.svg'
import PremiumQuality from '@public/images/calc/quality/3_b.svg'

export const configTemplate = {
  change: {
    roomConfigs: {}
  },
  rooms: []
}

export const yesNo = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const interiors = [
  {
    label: 'Dusche',
    image: Shower,
    value: 'shower'
  },
  {
    label: 'Badewanne',
    image: Bathtub,
    value: 'bathtub'
  },
  {
    label: 'Toilette',
    image: Toilet,
    value: 'toilet'
  },
  {
    label: 'Waschtisch',
    image: Sink,
    value: 'sink'
  },
  {
    label: 'Doppelwaschtisch',
    image: DoubleSink,
    value: 'doublesink'
  }
]

export const floors = [
  {
    label: 'Fliesen Budget',
    image: BudgetFloor,
    value: 'budget'
  },
  {
    label: 'Fliesen Standard',
    image: StandardFloor,
    value: 'standard'
  },
  {
    label: 'Fliesen Premium',
    image: PremiumFloor,
    value: 'premium'
  }
]

export const qualities = [
  {
    label: 'Budget',
    image: BudgetQuality,
    value: 'budget'
  },
  {
    label: 'Standard',
    image: StandardQuality,
    value: 'standard'
  },
  {
    label: 'Premium',
    image: PremiumQuality,
    value: 'premium'
  }
]
