import { z } from 'zod'

export const AmountSchema = z
  .object({
    enabled: z.boolean(),
    amount: z.number().optional()
  })
  .refine(
    (value) => {
      return !(value.enabled && !value.amount)
    },
    {
      message: 'amount is required when enabled is true'
    }
  )

export default AmountSchema
