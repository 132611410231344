<template>
  <div>
    <svg
      :class="height"
      @click="open = true"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-primary hover:cursor-pointer"
      viewBox="0 0 256 256"
    >
      <path
        d="M140 176a4 4 0 0 1-4 4a12 12 0 0 1-12-12v-40a4 4 0 0 0-4-4a4 4 0 0 1 0-8a12 12 0 0 1 12 12v40a4 4 0 0 0 4 4a4 4 0 0 1 4 4m-16-84a8 8 0 1 0-8-8a8 8 0 0 0 8 8m104 36A100 100 0 1 1 128 28a100.11 100.11 0 0 1 100 100m-8 0a92 92 0 1 0-92 92a92.1 92.1 0 0 0 92-92"
      />
    </svg>

    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-50" @close="open = false">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
        </TransitionChild>

        <div class="fixed inset-0 z-[1000] w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-primary px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6"
              >
                <div class="absolute right-0 top-0 pr-6 pt-6">
                  <button
                    type="button"
                    class="rounded-md bg-action p-1 text-primary hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="open = false"
                  >
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div class="mt-3 sm:ml-4 sm:mt-0">
                    <DialogTitle as="h3" class="mb-4 text-xl font-semibold leading-6 text-white">{{
                      title
                    }}</DialogTitle>
                    <div
                      class="grid gap-4"
                      :class="{
                        'md:grid-cols-2': gallery.length > 0
                      }"
                    >
                      <div class="prose prose-invert max-w-full overflow-auto ipad:max-h-[60vh]">
                        <slot />
                        <div class="pt-0" v-if="content" v-html="content" />
                      </div>
                      <div v-if="gallery.length">
                        <span class="text-white">Galerie folgt...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const open = ref(false)

defineProps({
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  },
  gallery: {
    type: Array,
    default: () => []
  },
  height: {
    type: String,
    default: 'h-5'
  }
})
</script>

<style scoped></style>
