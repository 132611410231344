<template>
  <div class="relative h-40 w-40">
    <svg class="h-full w-full" viewBox="0 0 100 100">
      <circle
        class="text-gray-300"
        stroke-width="10"
        stroke="currentColor"
        fill="transparent"
        r="45"
        cx="50"
        cy="50"
      />
      <circle
        class="progress-ring__circle text-primary"
        stroke-width="10"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="strokeDashoffset"
        stroke-linecap="round"
        stroke="currentColor"
        fill="transparent"
        r="45"
        cx="50"
        cy="50"
      />
    </svg>
    <div class="absolute inset-0 flex items-center justify-center text-lg font-bold text-primary">
      {{ Math.round(percentage) }}%
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const percentage = ref(0)
const circumference = 2 * Math.PI * 45

const strokeDashoffset = computed(() => {
  return circumference - (percentage.value / 100) * circumference
})

onMounted(() => {
  const interval = setInterval(() => {
    percentage.value += 1
    if (percentage.value >= 100) {
      clearInterval(interval)
    }
  }, 25)
})
</script>

<style scoped>
.progress-ring__circle {
  transition: stroke-dashoffset 0.05s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
