export const sliceBeforeThirdUnderscore = (str) => {
  const parts = str.split('_')
  if (parts.length < 3) {
    return str // Return the whole string if there are less than three parts
  }
  return parts.slice(0, 2).join('_')
}
export const findServiceTotal = (totals, stepLabel) => {
  if (!totals || !totals.services || !Array.isArray(totals.services)) {
    return 0
  }

  const service = totals.services.find((service) => service.name === stepLabel)
  if (service && service.total && service.total.value) {
    return service.total.value
  } else {
    return 0
  }
}
export const getServiceOfStep = (str) => {
  return str.split('_')[1]
}
