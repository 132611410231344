<template>
  <div class="relative h-2 w-full overflow-hidden rounded-full bg-gray-200">
    <div
      class="h-full bg-primary-500 transition-all duration-300 ease-in-out"
      :style="{ width: progress + '%' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100
    }
  }
}
</script>

<style></style>
