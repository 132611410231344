import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'
import AmountSchema from '@/data/validations/types/amount.js'
import TypeSchema from '@/data/validations/types/type.js'

const WoodStepsSchema = z
  .object({
    enabled: z.boolean(),
    addWoodSteps: AmountSchema
  })
  .refine(
    (value) => {
      return !(value.enabled && !value.addWoodSteps.enabled)
    },
    {
      message: 'addWoodSteps is required when enabled is true'
    }
  )

export const StairsSchema = z.object({
  rebuild: TypeSchema,
  renew: WoodStepsSchema
})

export default StairsSchema
