import Yes from '@public/images/yes.svg'
import No from '@public/images/no.svg'
import Parkett from '@public/images/singles/floor/t_parkett.svg'
import Diele from '@public/images/singles/floor/t_diele.svg'
import Vinyl from '@public/images/singles/floor/t_vinyl.svg'
import Laminat from '@public/images/singles/floor/t_laminat.svg'
import Teppich from '@public/images/singles/floor/t_teppich.svg'
import Fliesen from '@public/images/singles/floor/t_fliesen.svg'
import BudgetQuality from '@public/images/calc/quality/1_b.svg'
import StandardQuality from '@public/images/calc/quality/2_b.svg'
import PremiumQuality from '@public/images/calc/quality/3_b.svg'
import ChangeFloor from '@public/images/singles/floor/f_change.svg'
import RecycleWoodenFloor from '@public/images/singles/floor/f_wood.svg'
import LivingRoom from '@public/images/calc/rooms/livingroom.svg'
import Bedroom from '@public/images/calc/rooms/bedroom.svg'
import Floor from '@public/images/calc/rooms/flur.svg'
import Kitchen from '@public/images/calc/rooms/kitchen.svg'
import UtilityRoom from '@public/images/calc/rooms/utility_room.svg'

export const yesNo = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const floors = [
  {
    label: 'Parket',
    image: Parkett,
    value: 'parkett'
  },
  {
    label: 'Diele',
    image: Diele,
    value: 'diele'
  },
  {
    label: 'Vinyl',
    image: Vinyl,
    value: 'vinyl'
  },
  {
    label: 'Laminat',
    image: Laminat,
    value: 'laminat'
  },
  {
    label: 'Teppich',
    image: Teppich,
    value: 'teppich'
  },
  {
    label: 'Fliesen',
    image: Fliesen,
    value: 'fliesen'
  }
]

export const qualities = [
  {
    label: 'Budget - Qualität',
    image: BudgetQuality,
    value: 'budget'
  },
  {
    label: 'Standard - Qualität',
    image: StandardQuality,
    value: 'standard'
  },
  {
    label: 'Premium - Qualität',
    image: PremiumQuality,
    value: 'premium'
  }
]

export const types = [
  {
    label: 'Neuen Boden verlegen',
    value: 'change',
    image: ChangeFloor
  },
  {
    label: 'Holzboden aufbereiten',
    value: 'recycleWoodenFloor',
    image: RecycleWoodenFloor
  }
]

export const roomTypes = [
  {
    label: 'Wohn-/Esszimmer',
    value: 'living_room',
    image: LivingRoom
  },
  {
    label: 'Schlaf-/Kinderzimmer',
    value: 'bedroom',
    image: Bedroom
  },
  {
    label: 'Flur',
    value: 'floor',
    image: Floor
  },
  {
    label: 'Küche',
    value: 'kitchen',
    image: Kitchen
  },
  {
    label: 'Hauswirtschaftsraum / Keller',
    value: 'utility_room',
    image: UtilityRoom
  }
]

export const configTemplate = {
  selectedRoomTypes: [],
  roomAmounts: {
    living_room: 0,
    kitchen: 0,
    bathroom: 0,
    utility_room: 0,
    cellar: 0,
    bedroom: 0
  },
  rooms: [],
  toIgnoreRooms: [],
  change: {
    quality: 'standard',
    rooms: {}
  },
  recycleWoodenFloor: [],
  newSkirtingBoards: {
    rooms: [],
    quality: 'standard'
  },
  removeOld: {
    rooms: []
  }
}
