import { z } from 'zod'

export const TypeSchema = z
  .object({
    enabled: z.boolean(),
    type: z.string().optional()
  })
  .refine(
    (value) => {
      return !(value.enabled && !value.type)
    },
    {
      message: 'type is required when enabled is true'
    }
  )

export default TypeSchema
