import SingleFamily from '@public/images/singles/facade/h_single.svg'
import SemiDetached from '@public/images/singles/facade/h_double.svg'
import Terraced from '@public/images/singles/facade/h_row.svg'
import EndTerraced from '@public/images/singles/facade/h_row_end.svg'
import ClinkerFacade from '@public/images/singles/facade/f_riemchen.svg'
import PlasterFacade from '@public/images/singles/facade/f_plaster.svg'
import PaintFacade from '@public/images/singles/facade/f_paint.svg'
import BlownInsulation from '@public/images/singles/facade/f_blown_insulation.svg'

export const houseTypes = [
  {
    label: 'Einfamilienhaus',
    value: 'single',
    image: SingleFamily
  },
  {
    label: 'Doppelhaushälfte',
    value: 'double',
    image: SemiDetached
  },
  {
    label: 'Reihenhaus',
    value: 'row',
    image: Terraced
  },
  {
    label: 'Reihenendhaus',
    value: 'rowend',
    image: EndTerraced
  }
]

export const facades = [
  {
    label: 'Fassadendämmung in Steinoptik',
    value: 'clinker',
    image: ClinkerFacade
  },
  {
    label: 'Fassadendämmung mit Putz',
    value: 'plaster',
    image: PlasterFacade
  },
  {
    label: 'Fassade streichen',
    value: 'paint',
    image: PaintFacade
  },
  {
    label: 'Einblasdämmung',
    value: 'blown_insulation',
    image: BlownInsulation
  }
]
