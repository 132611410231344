<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { defineProps, computed } from 'vue'
import AnimatedNumber from '../Inputs/AnimatedNumber.vue'
import scrollToSelector from '@/utils/scroll.js'
import { ChevronUpIcon } from '@heroicons/vue/24/outline'
import { availableSteps } from '@/utils/calculators.js'
import Summary from '@/components/Structure/V2/SummaryBar/Summary.vue'

const props = defineProps({
  isProject: Boolean,
  isInternal: Boolean,
  modelValue: Object,
  totals: Object,
  options: {
    type: Array,
    required: false
  }
})

const emit = defineEmits(['update:modelValue'])

const handleStepActivated = (step, close) => {
  // Close the disclosure panel when a step is activated
  if (typeof close === 'function') {
    close()
  }
}

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const toTop = () => {
  scrollToSelector('#grid')
}

const finish = () => {
  if (props.isProject && window._widget) {
    window._widget.saveProject(props.modelValue, props.totals)
    return
  }

  if (props.isInternal) {
    return
  }

  if (window.matchMedia('(max-width: 789px)').matches) {
    if (model.value.step.startsWith('base')) {
      model.value.step = 'rooms'
    } else if (model.value.step.startsWith('rooms')) {
      model.value.step = 'services'
    } else if (model.value.step.startsWith('services')) {
      model.value.step = 'contact'
    }

    return
  }

  model.value.step = 'contact'
}
</script>
<template>
  <Disclosure v-slot="{ open, close }">
    <DisclosureButton
      :class="isInternal ? '' : 'laptop:left-10 left-2'"
      class="laptop:bottom-10 laptop:right-10 fixed bottom-0 right-2 z-40 ipad:bottom-1"
    >
      <div
        class="laptop:max-w-3xl z-50 mx-auto flex max-w-3xl flex-col items-center rounded-t-xl bg-primary dark:bg-white ipad:rounded-xl"
      >
        <div class="flex flex-col justify-center ipad:hidden">
          <ChevronUpIcon
            class="h-6 w-6 transform text-center text-action transition-all"
            :class="{
              '-rotate-180': open
            }"
          />
        </div>
        <div
          class="mx-auto flex w-full cursor-default items-center justify-between rounded-t-xl bg-primary px-4 py-1 dark:bg-white md:py-4"
        >
          <span class="text-lg font-bold text-white dark:text-black ipad:text-2xl">
            Kostenschätzung total:
          </span>
          <div class="flex items-center">
            <AnimatedNumber
              :model-value="totals.total.value"
              class="text-md font-bold text-white dark:text-black ipad:text-2xl"
            />
          </div>
        </div>
      </div>
    </DisclosureButton>
    <transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform translate-y-full opacity-0"
      enter-to-class="transform translate-y-0 opacity-100"
      leave-active-class="transition duration-300 ease-in"
      leave-from-class="transform translate-y-0 opacity-100"
      leave-to-class="transform translate-y-full opacity-0"
    >
      <DisclosurePanel
        class="laptop:left-10 laptop:right-10 pointer-events-none fixed bottom-[70px] left-2 right-2 z-[1000] md:bottom-[85px] ipad:bottom-[90px] ipad:hidden"
      >
        <div class="mx-auto flex max-w-3xl justify-center rounded-xl border border-gray-200">
          <div
            class="pointer-events-auto w-full overflow-auto rounded-xl bg-primary p-2 shadow-lg dark:bg-white"
            style="max-height: 80vh"
          >
            <div>
              <h2 class="text-xxl mb-2 border-b pb-6 text-center text-white">
                Ihre Leistungen im Überblick:
              </h2>

              <div>
                <Summary
                  v-model="model"
                  :options="props.options"
                  :totals="totals"
                  v-if="model.step !== 'services'"
                  @step-activated="(step) => handleStepActivated(step, close)"
                />
              </div>
            </div>
          </div>
        </div>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>
