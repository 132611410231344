import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'
import AmountSchema from '@/data/validations/types/amount.js'

export const BathsSchema = z.object({
  change: z.object({
    enabled: z.boolean(),
    quality: z.string().optional(),
    currentRoom: z.string().optional(),
    type: z.string().optional()
  })
})

export default BathsSchema
