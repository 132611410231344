<script setup>
import { ref, watch, onMounted } from 'vue'
import anime from 'animejs'

const props = defineProps({
  modelValue: {
    type: Number,
    required: true
  },
  duration: {
    type: Number,
    default: 500
  },
  formatter: {
    type: Function,
    default: (value) => {
      return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
    }
  }
})

const count = ref(0)

const animateCount = (targetValue) => {
  anime({
    targets: { value: count.value },
    value: targetValue,
    duration: props.duration,
    easing: 'linear',
    round: 1,
    update: (anim) => {
      count.value = anim.animations[0].currentValue
    }
  })
}

watch(
  () => props.modelValue,
  (newValue) => {
    animateCount(newValue)
  }
)

onMounted(() => {
  animateCount(props.modelValue)
})
</script>

<template>
  <span>{{ formatter(count) }}</span>
</template>
