import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'
import AmountSchema from '@/data/validations/types/amount.js'

const RenewSchema = z
  .object({
    enabled: z.boolean(),
    size: z.number().optional(),
    type: z.string().optional(),
    quality: z.string().optional()
  })
  .refine(
    (value) => {
      return !(value.enabled && (!value.amount || !value.type))
    },
    {
      message: 'amount and type is required when enabled is true'
    }
  )
export const FacadeSchema = z.object({
  renew: RenewSchema
})

export default FacadeSchema
