import WindowOuter from '@public/images/singles/window/window_outer.svg'
import WindowInner from '@public/images/singles/window/window_inner.svg'
import WindowBoth from '@public/images/singles/window/window_both.svg'
import YesNo from '@public/images/singles/window/y_n.svg'
import NewWindow from '@public/images/singles/window/new_window.svg'
import WindowPaint from '@public/images/singles/window/window_paint.svg'
import Plastic from '@public/images/singles/window/plastic.svg'
import Wood from '@public/images/singles/window/wood.svg'
import WindowSingleLeaf from '@public/images/singles/window/window_single_leaf.svg'
import WindowDoubleLeaf from '@public/images/singles/window/window_double_leaf.svg'
import BalconyTerraceDoor from '@public/images/singles/window/balcony_terrace_door.svg'
import WindowLargeFixed from '@public/images/singles/window/window_large_fixed.svg'
import WindowTiltTurnSlide from '@public/images/singles/window/window_tilt_turn_slide.svg'
import WindowSmall from '@public/images/singles/window/window_small.svg'

export const window_outer_inner = [
  {
    label: 'Außen',
    value: 'outer',
    image: WindowOuter
  },
  {
    label: 'Innen',
    value: 'inner',
    image: WindowInner
  },
  {
    label: 'Beides',
    value: 'both',
    image: WindowBoth
  }
]

export const window_sills_options = [
  {
    label: 'Innen',
    value: 'inner',
    image: WindowInner
  },
  {
    label: 'Außen',
    value: 'outer',
    image: WindowOuter
  },
  {
    label: 'Beides',
    value: 'both',
    image: WindowBoth
  },
  {
    label: 'Nein',
    value: false,
    image: YesNo
  }
]

export const window_conf = [
  {
    label: 'Neue Fenster',
    value: 'change',
    image: NewWindow
  },
  {
    label: 'Fenster lackieren',
    value: 'lack',
    image: WindowPaint
  }
]

export const window_material = [
  {
    label: 'Kunststoff',
    value: 'plastic',
    image: Plastic
  },
  {
    label: 'Holz',
    value: 'wood',
    image: Wood
  }
]

export const window_type = [
  {
    label: 'Fenster einfach',
    value: 'singleLeaf',
    image: WindowSingleLeaf
  },
  {
    label: 'Fenster doppelt',
    value: 'doubleLeaf',
    image: WindowDoubleLeaf
  },
  {
    label: 'Balkontür',
    value: 'balconyTerraceDoor',
    image: BalconyTerraceDoor
  },
  {
    label: 'großes Fenster',
    value: 'largeFixed',
    image: WindowLargeFixed
  },
  {
    label: 'Schiebeelement',
    value: 'tiltTurnSlide',
    image: WindowTiltTurnSlide
  },
  {
    label: 'Fenster klein',
    value: 'small',
    image: WindowSmall
  }
]
