<template>
  <nav
    :class="[
      'flex items-center justify-center gap-5',
      'md:mt-10', // Add margin top on medium screens and up
      fixed
        ? [
            'fixed bottom-0 left-0 right-0 w-full rounded-t-2xl border-t border-gray-300 bg-primary p-4 sm:border-none md:relative md:w-auto md:bg-transparent md:p-0'
          ]
        : ['mt-10']
    ]"
  >
    <button
      :class="['btn px-10 py-3 text-xl', fixed ? 'md:btn--light text-white' : 'btn--light']"
      :disabled="
        deactivatePrev ||
        model.step === 'start' ||
        model.step.includes('start') ||
        model.step === 'types'
      "
      @click="prev"
    >
      zurück
    </button>
    <button
      :class="['btn px-10 py-3 text-xl', fixed ? 'btn--action md:btn--primary' : 'btn--primary']"
      :disabled="deactivateNext || disabled"
      @click="next"
    >
      weiter
    </button>
  </nav>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {},
  prev: String,
  step: String,
  disabled: Boolean,
  deactivatePrev: Boolean,
  deactivateNext: Boolean,
  fixed: Boolean // Controls fixed positioning on mobile
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const prev = () => {
  model.value.step = props.prev
}

const next = () => {
  model.value.step = props.step
}
</script>
