import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'

export const WallsCeilingsSchema = z.object({
  removeOld: RoomsSchema,
  ceilingSpots: RoomsSchema,
  improveStucco: RoomsSchema,
  newStucco: RoomsSchema
})

export default WallsCeilingsSchema
