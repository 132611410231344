import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'
import AmountSchema from '@/data/validations/types/amount.js'
import TypeSchema from '@/data/validations/types/type.js'
import QualitySchema from '@/data/validations/types/quality.js'

export const DoorsSchema = z.object({
  renew: AmountSchema,
  entranceDoor: QualitySchema,
  lack: AmountSchema
})

export default DoorsSchema
