<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import track from '@/utils/tracking.js'
import { getGclid, getUtmParams } from '@/utils/gclid'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import { services, houseTypes } from '@/data/options/v2/base.js'

const props = defineProps({
  form: Object,
  modelValue: Object,
  enabledServices: Array,
  headlineText: String
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const selectedServices = ref([])

const serviceCompete = computed({
  get: () => {
    return selectedServices.value
  },
  set: (value) => {
    selectedServices.value = value

    // Ensure model.value.services is an object
    const availableServices = Object.values(model.value.services)

    // Disable all services first
    availableServices.forEach((service) => {
      service.enabled = false
    })

    // Enable the selected services
    value.forEach((selectedService) => {
      if (model.value.services[selectedService]) {
        model.value.services[selectedService].enabled = true
      }
    })
  }
})

const dynamicSteps = computed(() => {
  const baseSteps = ['services']

  // Check if props.enabledServices and its value are defined and not empty
  if (props.enabledServices && Array.isArray(props.enabledServices)) {
    const serviceSteps = props.enabledServices.map((service) => service)
    const finalSteps = ['contact']
    return [...baseSteps, ...serviceSteps, ...finalSteps]
  } else {
    // If props.enabledServices is not defined or empty,
    // return only baseSteps and finalSteps
    return [...baseSteps, 'contact']
  }
})

const currentStepIndex = computed(() => {
  return dynamicSteps.value.indexOf(model.value.step)
})

const nextStep = computed(() => {
  const nextIndex = currentStepIndex.value + 1
  const nextStepTitle =
    nextIndex < dynamicSteps.value.length ? `services_${dynamicSteps.value[nextIndex]}` : null
  return nextStepTitle
})

const filteredServices = computed(() => {
  return Object.values(
    Object.fromEntries(
      Object.entries(services).filter(([_, service]) =>
        service.forTypes.includes(model.value.base.type)
      )
    )
  )
})

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()
  model.value.utm = getUtmParams()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'services',
              content_name: 'services'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: formData.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('services', data.volume / 100, 'EUR', data.id, formData.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}
</script>

<template>
  <div class="mt-5">
    <form @submit.prevent="submitHandler" id="form" class="">
      <div class="flex flex-col gap-6 md:gap-10" v-if="model.step === 'types'">
        <Headline>{{ headlineText }}</Headline>
        <Headline>Wo wohnen sie?</Headline>

        <IconsOptionsSelection
          v-model="model.base.type"
          :options="houseTypes"
          cols="md:grid-cols-2"
        />
        <SingleNavigation
          id="baseTypeButton"
          step="services"
          v-model="model"
          :disabled="!model.base.type"
        />
      </div>

      <div class="flex flex-col gap-2 md:gap-7" id="services" v-if="model.step === 'services'">
        <div class="flex flex-col gap-1 md:gap-5">
          <Headline>{{ headlineText }}</Headline>
        </div>
        <IconsOptionsSelection
          v-if="model.base.type"
          v-model="serviceCompete"
          :options="filteredServices"
          multiple
          :cols="filteredServices.length > 9 ? 'md:grid-cols-4' : 'md:grid-cols-3 grid-cols-2'"
        />
        <SingleNavigation
          :step="nextStep"
          prev="types"
          v-model="model"
          :disabled="enabledServices.length === 0"
        />
      </div>
    </form>
  </div>
</template>
