<template>
  <div class="flex items-center gap-1 md:gap-2" id="counter">
    <button
      id="decrease"
      class="rounded-md border border-gray-300 bg-primary px-2 py-1 font-normal text-white transition-colors duration-200 md:px-3 md:py-1"
      @click="decrement"
      @mousedown="onMouseDown($event)"
      @mouseup="onMouseUp($event)"
      @mouseleave="onMouseUp($event)"
      @touchstart="onMouseDown($event)"
      @touchend="onMouseUp($event)"
    >
      -
    </button>
    <span
      id="counter-value"
      class="rounded-md border bg-primary px-2 py-1 font-normal text-white md:px-3"
    >
      {{ modelValue }}
    </span>
    <button
      id="increase"
      class="rounded-md border border-gray-300 bg-primary px-2 py-1 font-normal text-white transition-colors duration-200 md:px-3 md:py-1"
      @click="increment"
      @mousedown="onMouseDown($event)"
      @mouseup="onMouseUp($event)"
      @touchstart="onMouseDown($event)"
    >
      +
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: true
    }
  },
  methods: {
    increment() {
      this.$emit('update:modelValue', this.modelValue + 1)
    },
    decrement() {
      if (this.modelValue > 0) {
        this.$emit('update:modelValue', this.modelValue - 1)
      }
    },
    onMouseDown(event) {
      event.target.classList.add('bg-gray-400')
    },
    onMouseUp(event) {
      event.target.classList.remove('bg-gray-400')
    }
  }
}
</script>

<style scoped>
.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}
</style>
