import { z } from 'zod'
import RoomsSchema from '@/data/validations/types/rooms.js'

const HeatingSystemSchema = z
  .object({
    enabled: z.boolean(),
    type: z.string().optional()
  })
  .refine(
    (value) => {
      if (value.enabled && !value.type) {
        return false
      }
      return true
    },
    {
      message: 'type is required when enabled is true'
    }
  )

const GeyserSchema = z
  .object({
    enabled: z.boolean(),
    amount: z.number().optional()
  })
  .refine(
    (value) => {
      if (value.enabled && value.amount < 1) {
        return false
      }
      return true
    },
    {
      message: 'amount is required when enabled is true'
    }
  )

const RadiatorSchema = z
  .object({
    enabled: z.boolean(),
    items: z
      .array(
        z.object({
          amount: z.number()
        })
      )
      .optional()
  })
  .refine(
    (value) => {
      if (
        value.enabled &&
        (!value.items ||
          value.items.length === 0 ||
          value.items.filter((item) => item.amount < 1).length === value.items.length)
      ) {
        return false
      }
      return true
    },
    {
      message: 'items are required when enabled is true'
    }
  )

const SwitchFeature = z
  .object({
    enabled: z.boolean(),
    quality: z.enum(['budget', 'standard', 'premium']).optional(),
    exhaust: z.object({
      enabled: z.boolean()
    })
  })
  .refine(
    (value) => {
      return !(value.enabled && !value.quality)
    },
    {
      message: 'quality is required when enabled is true'
    }
  )

export const HeatingSchema = z.object({
  heating_system: HeatingSystemSchema,
  heating_rebuild: HeatingSystemSchema,
  geyser: GeyserSchema,
  radiator: RadiatorSchema,
  underfloor_heating: RoomsSchema,
  stove: SwitchFeature,
  chimney: SwitchFeature
})

export default HeatingSchema
