type GclidRecord = {
  value: string
  expiryDate: number
}

function getParam(p: string) {
  const match = RegExp(`[?&]${p}=([^&]*)`).exec(window.location.search)

  return match && match[1] && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

type UtmParams<T = string> = {
  [key: string]: T
}

// Function to get URL parameters
function getUtmSearchParams() {
  const params = new URLSearchParams(window.location.search)
  const utmParams: UtmParams = {}

  // Loop through each UTM parameter and save it if it exists
  params.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParams[key.replace('utm_', '')] = value
    }
  })

  return utmParams
}

function getExpiryRecord(value: string) {
  const expiryPeriod = 90 * 24 * 60 * 60 * 1000 // 90 day expiry in milliseconds

  const expiryDate = new Date().getTime() + expiryPeriod
  return {
    value,
    expiryDate
  }
}

export const getUtmParams = () => {
  if (typeof localStorage === 'undefined') {
    return {}
  }

  const utm = localStorage.getItem('utm')

  if (!utm) {
    return {}
  }

  const utmParams = JSON.parse(utm)

  return utmParams
}

function addGclid() {
  const gclidParam = getParam('gclid')

  const gclsrcParam = getParam('gclsrc')
  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1

  if (gclidParam && isGclsrcValid) {
    const gclidRecord = getExpiryRecord(gclidParam)
    localStorage.setItem('gclid', JSON.stringify(gclidRecord))
  }
}

export const getGclid = () => {
  if (typeof localStorage === 'undefined') {
    return null
  }

  const gclid = localStorage.getItem('gclid')

  if (!gclid) {
    return null
  }

  const gclidRecord: GclidRecord | null = JSON.parse(gclid) as GclidRecord | null

  return gclidRecord && gclidRecord.value
}

// Save UTM parameters to localStorage
function saveUtmToLocalStorage() {
  const utmParams = getUtmSearchParams()

  // Check if there are UTM parameters to store
  if (Object.keys(utmParams).length > 0) {
    localStorage.setItem('utm', JSON.stringify(utmParams))
  }
}

export const registerGclid = () => {
  addGclid()
  saveUtmToLocalStorage()

  window.addEventListener('load', addGclid)
  window.addEventListener('load', saveUtmToLocalStorage)
}
