<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    class="h-[1em]"
  >
    <defs>
      <clipPath id="d93b98a874">
        <path
          d="M 30 3.53125 L 366 3.53125 L 366 339 L 30 339 Z M 30 3.53125 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#d93b98a874)">
      <path
        fill="#001932"
        d="M 327.414062 41.570312 C 303.089844 17.238281 270.753906 3.863281 236.367188 3.863281 C 201.960938 3.863281 169.625 17.238281 145.292969 41.570312 C 120.957031 65.882812 107.558594 98.242188 107.558594 132.648438 C 107.558594 162.554688 117.722656 190.886719 136.347656 213.777344 L 111.488281 238.636719 L 105.824219 232.972656 L 30.40625 308.367188 L 60.59375 338.554688 L 135.988281 263.160156 L 130.347656 257.496094 L 155.207031 232.636719 C 178.097656 251.285156 206.425781 261.425781 236.335938 261.425781 C 270.742188 261.425781 303.078125 248.027344 327.414062 223.691406 C 377.636719 173.480469 377.636719 91.78125 327.414062 41.570312 Z M 313.511719 209.769531 C 292.894531 230.386719 265.503906 241.722656 236.335938 241.722656 C 207.1875 241.722656 179.800781 230.375 159.179688 209.769531 C 138.585938 189.171875 127.238281 161.785156 127.238281 132.636719 C 127.238281 103.492188 138.585938 76.078125 159.191406 55.484375 C 179.789062 34.867188 207.175781 23.527344 236.347656 23.527344 C 265.492188 23.527344 292.882812 34.878906 313.5 55.484375 C 356.03125 98.039062 356.03125 167.257812 313.511719 209.769531 Z M 313.511719 209.769531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </g>
    <path
      fill="#001932"
      d="M 236.347656 38.203125 C 211.117188 38.203125 187.421875 48.039062 169.578125 65.859375 C 166.894531 68.546875 166.894531 72.921875 169.578125 75.609375 C 172.265625 78.296875 176.644531 78.296875 179.328125 75.609375 C 194.5625 60.375 214.8125 52.003906 236.347656 52.003906 C 257.902344 52.003906 278.140625 60.375 293.386719 75.609375 C 294.71875 76.964844 296.484375 77.625 298.265625 77.625 C 300.011719 77.625 301.78125 76.964844 303.144531 75.609375 C 305.832031 72.921875 305.832031 68.546875 303.144531 65.859375 C 285.292969 48.039062 261.574219 38.203125 236.347656 38.203125 Z M 236.347656 38.203125 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="#001932"
      d="M 12.722656 326.074219 C 5.019531 333.753906 5.019531 346.234375 12.722656 353.902344 L 15.0625 356.261719 C 22.761719 363.941406 35.21875 363.941406 42.910156 356.261719 L 53.273438 345.898438 L 23.085938 315.710938 Z M 12.722656 326.074219 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      stroke-linecap="butt"
      transform="matrix(0.53033, 0.53033, -0.53033, 0.53033, 317.353196, 43.418856)"
      fill="none"
      stroke-linejoin="miter"
      d="M 0.00130701 0.497888 L 2.188919 0.497888 "
      stroke="#001932"
      stroke-width="1"
      stroke-opacity="1"
      stroke-miterlimit="4"
    />
  </svg>
</template>
