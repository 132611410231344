<template>
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-10">
        <Headline>{{ headlineText }}</Headline>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_roof_start'">
        <Headline>Soll das Dach erneuert oder gedämmt werden?</Headline>
        <IconsOptionsSelection :options="startType" cols="md:grid-cols-2" v-model="changeType" />
        <SingleNavigation
          :step="
            model.services.roof.change.type === 'renew'
              ? 'services_roof_type'
              : 'services_roof_renew_size'
          "
          v-model="model"
          :disabled="!model.services.roof.change.type"
        />
      </template>

      <template v-if="model.step === 'services_roof_type'">
        <Headline>Um welche Dachform handelt es sich?</Headline>
        <IconsOptionsSelection
          cols="md:grid-cols-2"
          :options="roofFormsOptions"
          v-model="model.services.roof.renew.form"
        />
        <SingleNavigation
          v-if="model.services.roof.change.type === 'renew'"
          step="services_roof_renew_size"
          prev="services_roof_start"
          v-model="model"
          :disabled="!model.services.roof.renew.form"
        />
        <SingleNavigation
          v-else
          step="services_roof_renew_size"
          prev="services_roof_start"
          v-model="model"
          :disabled="!model.services.roof.renew.form"
        />
      </template>

      <template v-if="model.step === 'services_roof_renew_size'">
        <Headline>Wie groß ist Ihre Dachfläche?</Headline>
        <div class="flex flex-row justify-center">
          <span>(ein durchschnittliches Einfamilienhaus hat ca. 100qm Dachfläche)</span>
        </div>
        <Range v-model="roofSize" :max="400" />
        <SingleNavigation
          v-if="model.services.roof.change.type === 'renew'"
          step="services_roof_current_paving"
          prev="services_roof_type"
          v-model="model"
          :disabled="!model.services.roof.renew.area"
        />
        <SingleNavigation
          v-else
          step="services_roof_final"
          prev="services_roof_start"
          v-model="model"
          :disabled="!model.services.roof.insulation.size"
        />
      </template>

      <template v-if="model.step === 'services_roof_current_paving'">
        <Headline>Welchen Belag hat Ihr aktuelles Dach?</Headline>
        <IconsOptionsSelection
          v-if="model.services.roof.renew.form === 'saddle'"
          cols="md:grid-cols-2"
          :options="currentPavingSaddle"
          v-model="model.services.roof.renew.currentPaving"
        />
        <IconsOptionsSelection
          v-else
          cols="md:grid-cols-2"
          :options="currentPavingFlat"
          v-model="model.services.roof.renew.currentPaving"
        />
        <SingleNavigation
          v-if="model.services.roof.change.type === 'renew'"
          step="services_roof_new_paving"
          prev="services_roof_renew_size"
          v-model="model"
          :disabled="!model.services.roof.renew.currentPaving"
        />
        <SingleNavigation
          v-else
          step="services_roof_new_paving"
          prev="services_roof_insulation_floorCeiling_size"
          v-model="model"
          :disabled="!model.services.roof.renew.currentPaving"
        />
      </template>

      <template v-if="model.step === 'services_roof_new_paving'">
        <Headline>Welchen Belag möchten Sie auf Ihrem Dach?</Headline>
        <IconsOptionsSelection
          v-if="model.services.roof.renew.form === 'saddle'"
          cols="md:grid-cols-2"
          :options="currentPavingSaddle"
          v-model="model.services.roof.renew.newPaving"
        />
        <IconsOptionsSelection
          v-else
          cols="md:grid-cols-2"
          :options="currentPavingFlat"
          v-model="model.services.roof.renew.newPaving"
        />
        <SingleNavigation
          step="services_roof_gutters"
          prev="services_roof_current_paving"
          v-model="model"
          :disabled="!model.services.roof.renew.newPaving"
        />
      </template>

      <template v-if="model.step === 'services_roof_gutters'">
        <Headline>Sollen auch die Fallrohre und Dachrinnen erneuert werden?</Headline>
        <IconsOptionsSelection cols="md:grid-cols-2" :options="yesNo" v-model="gutter" />
        <SingleNavigation
          step="services_roof_final"
          prev="services_roof_new_paving"
          v-model="model"
        />
      </template>
    </form>
  </section>
</template>

<script setup>
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import { computed, onMounted, ref } from 'vue'
import track from '@/utils/tracking.js'
import { getGclid, getUtmParams } from '@/utils/gclid'
import Range from '@/components/Inputs/Singles/Range.vue'
import {
  roofFormsOptions,
  yesNo,
  startType,
  currentPavingSaddle,
  currentPavingFlat
} from '@/data/options/v2/roof.js'
import services from '@/data/services.js'
import { ContactValidationSchema } from '@/utils/validators.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case '"services_roof_start"':
      return 0
    case 'services_roof_type':
      return 10
    case 'services_roof_insulation_floorCeiling_size':
      return 20
    case 'services_roof_renew_size':
      return 40
    case 'services_roof_current_paving':
      return 50
    case 'services_roof_new_paving':
      return 60
    case 'services_roof_gutters':
      return 80
    case 'services_roof_final':
      return 100
    default:
      return 0
  }
})

const gutter = computed({
  get: () => model.value.services.roof.renew.gutters.enabled,
  set: (v) => {
    model.value.services.roof.renew.gutters.enabled = v
    model.value.services.roof.renew.downspouts.enabled = v
  }
})

const changeType = computed({
  get: () => model.value.services.roof.change.type,
  set: (v) => {
    model.value.services.roof.change.type = v
    if (v === 'renew') {
      model.value.services.roof.insulation.size = 0
    } else {
      model.value.services.roof.renew.form = 'saddle'
      model.value.services.roof.renew.area = 0
      model.value.services.roof.renew.currentPaving = ''
      model.value.services.roof.renew.newPaving = ''
    }
  }
})

const roofSize = computed({
  get: () => {
    if (model.value.services.roof.change.type === 'renew') {
      return model.value.services.roof.renew.area
    } else {
      return model.value.services.roof.insulation.size
    }
  },
  set: (v) => {
    if (model.value.services.roof.change.type === 'renew') {
      model.value.services.roof.renew.area = v
    } else {
      model.value.services.roof.insulation.size = v
    }
  }
})
const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()
  model.value.utm = getUtmParams()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'floor',
              content_name: 'floor'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: formData.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('floor', data.volume / 100, 'EUR', data.id, formData.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}

onMounted(() => {
  model.value.step = 'services_roof_start'
  model.value.services.roof.enabled = true
  model.value.services.roof.change.enabled = true
  model.value.services.roof.renew.form = 'saddle'
})
</script>
