<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 810 810"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    class="h-[1em] text-current"
  >
    <defs>
      <clipPath id="d576a9013d">
        <path
          d="M 0 48.230469 L 810 48.230469 L 810 738.980469 L 0 738.980469 Z M 0 48.230469 "
          clip-rule="nonzero"
        />
      </clipPath>
    </defs>
    <path
      stroke-linecap="butt"
      transform="matrix(0.640347, -0.390456, 0.390456, 0.640347, 13.682981, 12.129681)"
      fill="none"
      stroke-linejoin="miter"
      d="M 0.00123542 2.000411 L 2.179869 1.999001 "
      stroke="#ffffff"
      stroke-width="4"
      stroke-opacity="1"
      stroke-miterlimit="4"
    />
    <g clip-path="url(#d576a9013d)">
      <path
        fill="currentColor"
        d="M 12.460938 726.101562 L 797.578125 726.101562 L 797.578125 60.78125 L 12.460938 60.78125 Z M 803.804688 738.5625 L 6.226562 738.5625 C 2.792969 738.5625 0 735.769531 0 732.335938 L 0 54.546875 C 0 51.101562 2.792969 48.308594 6.226562 48.308594 L 803.804688 48.308594 C 807.246094 48.308594 810.039062 51.101562 810.039062 54.546875 L 810.039062 732.335938 C 810.039062 735.769531 807.246094 738.5625 803.804688 738.5625 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
    </g>
    <path
      fill="currentColor"
      d="M 50.8125 689.1875 L 759.230469 689.1875 L 759.230469 97.671875 L 50.8125 97.671875 Z M 765.464844 701.648438 L 44.578125 701.648438 C 41.136719 701.648438 38.347656 698.855469 38.347656 695.414062 L 38.347656 91.441406 C 38.347656 88 41.136719 85.214844 44.578125 85.214844 L 765.464844 85.214844 C 768.90625 85.214844 771.699219 88 771.699219 91.441406 L 771.699219 695.414062 C 771.699219 698.855469 768.90625 701.648438 765.464844 701.648438 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 317.304688 615.363281 L 317.316406 615.363281 Z M 89.152344 135.65625 L 89.152344 651.042969 L 311.074219 610.171875 L 311.074219 171.175781 Z M 82.925781 664.75 C 81.484375 664.75 80.066406 664.25 78.9375 663.308594 C 77.515625 662.121094 76.691406 660.371094 76.691406 658.515625 L 76.691406 128.355469 C 76.691406 126.539062 77.492188 124.804688 78.878906 123.617188 C 80.265625 122.433594 82.101562 121.917969 83.910156 122.207031 L 318.289062 159.703125 C 321.304688 160.195312 323.53125 162.804688 323.53125 165.851562 L 323.53125 615.363281 C 323.53125 618.359375 321.386719 620.941406 318.425781 621.496094 L 84.050781 664.636719 C 83.675781 664.714844 83.296875 664.75 82.925781 664.75 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 200.8125 643.867188 C 197.375 643.867188 194.582031 641.074219 194.582031 637.640625 L 194.582031 147.214844 C 194.582031 143.773438 197.375 140.980469 200.8125 140.980469 C 204.253906 140.980469 207.046875 143.773438 207.046875 147.214844 L 207.046875 637.640625 C 207.046875 641.074219 204.253906 643.867188 200.8125 643.867188 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 317.304688 399.667969 L 82.925781 399.667969 C 79.484375 399.667969 76.691406 396.882812 76.691406 393.441406 C 76.691406 390 79.484375 387.207031 82.925781 387.207031 L 317.304688 387.207031 C 320.746094 387.207031 323.53125 390 323.53125 393.441406 C 323.53125 396.882812 320.746094 399.667969 317.304688 399.667969 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 342.527344 434.179688 C 339.085938 434.179688 336.300781 431.386719 336.300781 427.953125 C 336.300781 417.277344 336.300781 388.910156 332.996094 374.394531 C 331.808594 369.179688 329.015625 363.855469 317.304688 363.855469 C 313.859375 363.855469 311.070312 361.085938 311.070312 357.644531 C 311.074219 354.1875 313.859375 351.414062 317.304688 351.414062 C 336.511719 351.414062 343.042969 362.398438 345.144531 371.628906 C 348.347656 385.695312 348.753906 409.125 348.753906 427.953125 C 348.753906 431.386719 345.96875 434.179688 342.527344 434.179688 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 498.964844 610.234375 L 720.871094 651.042969 L 720.871094 135.65625 L 498.964844 171.175781 Z M 727.105469 664.75 C 726.734375 664.75 726.363281 664.714844 725.984375 664.636719 L 491.605469 621.558594 C 488.648438 621.007812 486.503906 618.425781 486.503906 615.425781 L 486.503906 165.851562 C 486.503906 162.804688 488.726562 160.195312 491.746094 159.703125 L 726.136719 122.207031 C 727.929688 121.917969 729.769531 122.433594 731.148438 123.617188 C 732.539062 124.804688 733.335938 126.539062 733.335938 128.355469 L 733.34375 658.515625 C 733.34375 660.371094 732.523438 662.121094 731.097656 663.308594 C 729.957031 664.25 728.550781 664.75 727.105469 664.75 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 609.222656 643.867188 C 605.78125 643.867188 602.988281 641.074219 602.988281 637.640625 L 602.996094 147.214844 C 602.996094 143.773438 605.78125 140.980469 609.222656 140.980469 C 612.65625 140.980469 615.449219 143.773438 615.449219 147.214844 L 615.449219 637.640625 C 615.449219 641.074219 612.65625 643.867188 609.222656 643.867188 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 727.105469 399.667969 L 492.730469 399.667969 C 489.289062 399.667969 486.503906 396.882812 486.503906 393.441406 C 486.503906 390 489.289062 387.207031 492.730469 387.207031 L 727.105469 387.207031 C 730.550781 387.207031 733.335938 390 733.335938 393.441406 C 733.34375 396.882812 730.550781 399.667969 727.105469 399.667969 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M 467.507812 434.179688 C 464.066406 434.179688 461.28125 431.386719 461.28125 427.953125 C 461.28125 409.125 461.6875 385.695312 464.890625 371.628906 C 466.988281 362.398438 473.523438 351.414062 492.730469 351.414062 C 496.171875 351.414062 498.964844 354.1875 498.964844 357.644531 C 498.964844 361.085938 496.171875 363.855469 492.730469 363.855469 C 481.019531 363.855469 478.226562 369.179688 477.039062 374.394531 C 473.734375 388.910156 473.734375 417.277344 473.734375 427.953125 C 473.734375 431.386719 470.949219 434.179688 467.507812 434.179688 "
      fill-opacity="1"
      fill-rule="evenodd"
    />
    <path
      stroke-linecap="butt"
      transform="matrix(0.495471, 0.563035, -0.563035, 0.495471, 281.903075, 91.549623)"
      fill="none"
      stroke-linejoin="miter"
      d="M -0.0012055 1.998337 L 4.534356 2.00037 "
      stroke="#001932"
      stroke-width="4"
      stroke-opacity="1"
      stroke-miterlimit="4"
    />
  </svg>
</template>
<script setup lang="ts"></script>
