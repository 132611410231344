<template xmlns="http://www.w3.org/1999/html">
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_electrical_start'">
        <Headline>Soll ein neuer Sicherungskasten verbaut werden?</Headline>
        <IconsOptionsSelection
          :options="security_box"
          v-model="selectedElectricalOptionComputed"
          cols="md:grid-cols-3"
        />
        <SingleNavigation
          v-if="selectedElectricalOption && model.base.type === 'house'"
          step="services_electrical_heatPump"
          prev="services_electrical_start"
          v-model="model"
          :disabled="!selectedElectricalOptionComputed"
        />
        <SingleNavigation
          v-else
          step="services_electrical_wiring"
          prev="services_electrical_start"
          v-model="model"
          :disabled="!selectedElectricalOptionComputed"
        />
      </template>

      <template
        v-else-if="
          model.step === 'services_electrical_heatPump' &&
          selectedElectricalOption &&
          model.base.type === 'house'
        "
      >
        <Headline> Ist eine Wärmepumpe vorhanden oder soll eine verbaut werden?</Headline>
        <IconsOptionsSelection
          :options="yesNoOptions"
          v-model="model.services.electrical.heat_pump.enabled"
          cols="md:grid-cols-2"
        />
        <SingleNavigation
          step="services_electrical_wiring"
          prev="services_electrical_start"
          v-model="model"
        />
      </template>

      <template v-if="model.step === 'services_electrical_wiring'">
        <Headline>Sollen die elektrischen Leitungen erneuert werden?</Headline>
        <IconsOptionsSelection
          name="house_type"
          :options="yesNoOptions"
          cols="md:grid-cols-2"
          v-model="model.services.electrical.electrical_wiring.enabled"
        />
        <SingleNavigation
          :step="stepAfterElectricalWiring"
          :prev="stepBeforeElectricalWiring"
          v-model="model"
        />
      </template>

      <template v-if="model.step === 'services_electrical_rooms'">
        <Headline>In welchen Zimmern sollen neue Leitungen verlegt werden?</Headline>
        <IconsOptionsSelection
          :options="roomTypes"
          cols="md:grid-cols-3"
          v-model="selectedRoomTypesComputed"
          @update:modelValue="updateSelectedRooms"
          multiple
        >
          <template #option-slot="{ option, isActive }">
            <div v-if="isActive" class="mt-3 flex w-full flex-row items-center justify-between">
              <span class="font-semibold">Anzahl:</span>
              <CounterInput
                v-model="roomAmounts[option.value]"
                @update:modelValue="updateSelectedRooms"
              />
            </div>
          </template>
        </IconsOptionsSelection>
        <SingleNavigation
          :step="getNextStep"
          prev="services_electrical_wiring"
          v-model="model"
          :disabled="!hasSelectedRooms"
        />
      </template>

      <!-- Dynamic Room Details Steps -->
      <template v-for="(room, index) in sortedRooms" :key="room.id">
        <template v-if="model.step === `services_electrical_size_${room.id}`">
          <Headline>
            Wie groß ist der folgende Raum:
            <span class="text-fancy">{{
              getRoomNameWithIndex(room.name, getSortedIndex(room))
            }}</span
            >?
          </Headline>
          <Range
            v-model="room.areas.floor"
            @update:modelValue="updateRoomSize(room.id)"
            :max="80"
          />
          <SingleNavigation
            :step="getNextRoomStep(index, sortedRooms)"
            :prev="getPreviousStep(index, sortedRooms)"
            v-model="model"
          />
        </template>
      </template>

      <template v-if="model.step === 'services_electrical_quality'">
        <Headline>Welche Qualität sollen die neuen Schalter und Steckdosen haben?</Headline>
        <IconsOptionsSelection
          name="house_type"
          :options="quality"
          cols="md:grid-cols-3"
          v-model="qualityComputed"
        />
        <SingleNavigation
          step="services_electrical_final"
          :prev="getPreviousStepForQuality"
          v-model="model"
          :disabled="!qualityComputed"
        />
      </template>
    </form>
  </section>
</template>

<script setup>
import { z } from 'zod'
import { computed, onMounted, ref, toRaw, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import { getImageUrl } from '@/utils/image.js'
import createRoom from '@/utils/room'
import Range from '@/components/Inputs/Singles/Range.vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import { getGclid, getUtmParams } from '@/utils/gclid'
import { useLocalStorage } from '@vueuse/core'
import {
  configTemplate,
  security_box,
  roomTypes,
  quality,
  yesNoOptions
} from '@/data/options/v2/electrical.js'
import { ContactValidationSchema } from '@/utils/validators.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const storedModel = useLocalStorage('electrical_service_model', { ...configTemplate })

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  const baseSteps = [
    'services_electrical_start',
    'services_electrical_heatPump',
    'services_electrical_wiring',
    'services_electrical_rooms'
  ]

  const roomSteps = ['services_electrical_size']

  const postRoomSteps = ['services_electrical_quality', 'services_electrical_final']

  // Generate steps for each room
  const roomSpecificSteps = storedModel.value.rooms.flatMap((room) =>
    roomSteps.map((step) => `${step}_${room.id}`)
  )

  // Combine all steps in the correct order
  const steps = [...baseSteps, ...roomSpecificSteps, ...postRoomSteps]

  // Find the current step index
  const currentStepIndex = steps.findIndex((step) => model.value.step === step)

  if (currentStepIndex === -1) return 0

  if (model.value.step === 'services_electrical_final') {
    return 100
  }

  // Calculate progress percentage
  return (currentStepIndex / (steps.length - 1)) * 100
})

const getNextStep = computed(() => {
  if (sortedRooms.value.length > 0) {
    return `services_electrical_size_${sortedRooms.value[0].id}`
  }
  return 'services_electrical_start'
})

const getNextRoomStep = (currentIndex, sortedRooms) => {
  const nextIndex = currentIndex + 1
  if (nextIndex < sortedRooms.length) {
    return `services_electrical_size_${sortedRooms[nextIndex].id}`
  }
  return 'services_electrical_quality'
}

const getPreviousStep = (currentIndex, sortedRooms) => {
  if (currentIndex > 0) {
    const prevIndex = currentIndex - 1
    return `services_electrical_size_${sortedRooms[prevIndex].id}`
  }
  return 'services_electrical_rooms'
}

const getPreviousStepForQuality = computed(() => {
  if (storedModel.value.rooms && storedModel.value.rooms.length > 0) {
    const lastRoomId = storedModel.value.rooms[storedModel.value.rooms.length - 1].id
    return `services_electrical_size_${lastRoomId}`
  }
  return 'services_electrical_wiring'
})

const selectedElectricalOption = ref()

const selectedElectricalOptionComputed = computed({
  get: () => {
    if (model.value.services.electrical.security_box.enabled) {
      return model.value.services.electrical.data_cabinet.enabled ? 'data_cabinet' : 'security_box'
    } else {
      return 'no'
    }
  },
  set: (value) => {
    selectedElectricalOption.value = value
    // Update model based on the selected option
    if (value === 'security_box') {
      model.value.services.electrical.security_box.enabled = true
      model.value.services.electrical.data_cabinet.enabled = false
    } else if (value === 'data_cabinet') {
      model.value.services.electrical.security_box.enabled = true
      model.value.services.electrical.data_cabinet.enabled = true
    } else {
      model.value.services.electrical.security_box.enabled = false
      model.value.services.electrical.data_cabinet.enabled = false
    }
  }
})

const updateRoomSize = (roomId) => {
  if (!storedModel.value?.rooms) {
    console.warn('Unable to update room size: storedModel.rooms is undefined')
    return
  }

  if (!model.value?.rooms) {
    console.warn('Unable to update room size: model.rooms is undefined')
    return
  }

  const storedRoom = storedModel.value.rooms.find((r) => r.id === roomId)
  if (!storedRoom) {
    console.warn(`Room with id ${roomId} not found in storedModel`)
    return
  }

  const modelRoom = model.value.rooms.find((r) => r.id === roomId)
  if (!modelRoom) {
    console.warn(`Room with id ${roomId} not found in model`)
    return
  }

  if (!storedRoom.areas || !modelRoom.areas) {
    console.warn(`Room with id ${roomId} has invalid structure (missing areas)`)
    return
  }

  storedRoom.areas.floor = modelRoom.areas.floor
}

const qualityComputed = computed({
  get: () => {
    if (model.value.services.electrical.switch_programm.quality) {
      return model.value.services.electrical.switch_programm.quality
    }
    return ''
  },
  set: (value) => {
    model.value.services.electrical.switch_programm.quality = value
  }
})

const submitting = ref(false)
const submitted = ref(false)

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()
  model.value.utm = getUtmParams()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'electric',
              content_name: 'electric'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('electric', data.volume / 100, 'EUR', data.id, model.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}

const stepAfterElectricalWiring = computed(() => {
  if (model.value.base.type === 'house') {
    return model.value.services.electrical.electrical_wiring.enabled && selectedElectricalOption
      ? 'services_electrical_rooms'
      : 'services_electrical_final'
  } else if (model.value.base.type === 'apartment') {
    return model.value.services.electrical.electrical_wiring.enabled
      ? 'services_electrical_rooms'
      : 'services_electrical_final'
  }
  return 'services_electrical_final'
})

const stepBeforeElectricalWiring = computed(() => {
  if (model.value.base.type === 'house') {
    if (
      model.value.services.electrical.data_cabinet.enabled ||
      model.value.services.electrical.security_box.enabled
    ) {
      return 'services_electrical_heatPump'
    } else {
      return 'services_electrical_start'
    }
  }
  return 'services_electrical_start'
})

const selectedRoomTypesComputed = computed({
  get: () => storedModel.value.selectedRoomTypes || [],
  set: (v) => {
    // Determine which rooms have been deselected
    const deselectedRooms = storedModel.value.selectedRoomTypes.filter((room) => !v.includes(room))

    // Reset the amount for deselected rooms
    deselectedRooms.forEach((room) => {
      roomAmounts.value[room] = 0
    })

    // Update the stored model with the new selection
    storedModel.value.selectedRoomTypes = v
  }
})

const roomAmounts = ref({
  living_room: 0,
  kitchen: 0,
  bathroom: 0,
  floor: 0,
  utility_room: 0,
  cellar: 0,
  bedroom: 0
})

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    storedModel.value.roomAmounts = roomAmounts.value
  },
  { deep: true }
)

const getRoomName = (originalRoomType) => {
  const roomTypeObj = roomTypes.find((type) => type.value === originalRoomType)
  return roomTypeObj ? roomTypeObj.label : 'Room'
}

const getRoomNameWithIndex = (label, overallIndex) => {
  // Find the room type object based on the label
  const roomTypeObj = roomTypes.find((room) => room.label === label)
  const roomName = roomTypeObj ? roomTypeObj.label : 'Room'

  // Filter rooms of the same type using the room's name (which should match the label)
  const roomsOfType = storedModel.value.rooms.filter((r) => r.name === label)
  const totalRoomsOfType = roomsOfType.length

  // Find the current room by its index in the sorted rooms array
  const currentRoom = sortedRooms.value[overallIndex]

  // Calculate the index of this room within its type group
  const roomTypeIndex = roomsOfType.findIndex((r) => r.id === currentRoom.id) + 1

  return totalRoomsOfType > 1 ? `${roomName} (${roomTypeIndex}/${totalRoomsOfType})` : roomName
}

const sortedRooms = computed(() => {
  return [...storedModel.value.rooms].sort((a, b) => {
    const indexA = roomTypes.findIndex((type) => type.value === a.type)
    const indexB = roomTypes.findIndex((type) => type.value === b.type)
    return indexA - indexB
  })
})

// When rooms are selected and added
const updateSelectedRooms = () => {
  storedModel.value.rooms = []
  selectedRoomTypesComputed.value.forEach((roomType) => {
    const amount = roomAmounts[roomType] || 1
    for (let i = 0; i < amount; i++) {
      storedModel.value.rooms.push({
        id: `${roomType}_${i + 1}`,
        type: roomType,
        areas: { floor: 0 }
      })
    }
  })
}

const getSortedIndex = (room) => {
  return sortedRooms.value.findIndex((r) => r.id === room.id)
}

const updateRooms = (roomType) => {
  const amount = roomAmounts.value[roomType]
  const roomsToIgnore = storedModel.value.toIgnoreRooms || []

  // Keep existing rooms of the current type, separating ignored and non-ignored rooms
  const existingRooms = model.value.rooms.filter((room) => room.type === roomType)
  const ignoredExistingRooms = existingRooms.filter((room) => roomsToIgnore.includes(room.id))
  const nonIgnoredExistingRooms = existingRooms.filter((room) => !roomsToIgnore.includes(room.id))

  // Keep rooms of other types
  const otherRooms = model.value.rooms.filter((room) => room.type !== roomType)

  let updatedRooms = [...nonIgnoredExistingRooms]

  // Add new rooms if needed
  while (updatedRooms.length < amount) {
    // Use the original roomType for naming
    const roomName = getRoomName(roomType)

    // Create the room with original name but effective room type
    const newRoom = createRoom(roomName, roomType, false, true)
    updatedRooms.push(newRoom)
  }

  // Remove excess rooms if needed, but don't remove ignored rooms
  if (updatedRooms.length > amount) {
    updatedRooms = updatedRooms.slice(0, amount)
  }

  // Update the rooms array, including ignored rooms
  model.value.rooms = [...otherRooms, ...ignoredExistingRooms, ...updatedRooms]

  model.value.services.electrical.electrical_wiring.rooms = model.value.rooms.map((room) => room.id)

  // Synchronize storedModel.value.rooms with model.value.rooms
  if (!storedModel.value.rooms) {
    storedModel.value.rooms = []
  }

  // Remove rooms from storedModel that are no longer in model, except ignored rooms
  storedModel.value.rooms = storedModel.value.rooms.filter(
    (storedRoom) =>
      model.value.rooms.some((modelRoom) => modelRoom.id === storedRoom.id) ||
      roomsToIgnore.includes(storedRoom.id)
  )

  // Add new rooms to storedModel or update existing ones, excluding ignored rooms
  model.value.rooms.forEach((modelRoom) => {
    if (!roomsToIgnore.includes(modelRoom.id)) {
      const existingStoredRoom = storedModel.value.rooms.find(
        (storedRoom) => storedRoom.id === modelRoom.id
      )
      if (existingStoredRoom) {
        // Update existing room, preserving its size
        Object.assign(existingStoredRoom, modelRoom, { areas: existingStoredRoom.areas })
      } else {
        // Add new room to localstorage
        storedModel.value.rooms.push({ ...modelRoom })
      }
    }
  })

  // Clean up electrical wiring rooms
  model.value.services.electrical.electrical_wiring.rooms =
    model.value.services.electrical.electrical_wiring.rooms.filter((roomId) =>
      model.value.rooms.some((room) => room.id === roomId)
    )
}

const hasSelectedRooms = computed(() => {
  return (
    storedModel.value.selectedRoomTypes.every((roomType) => roomAmounts.value[roomType] > 0) &&
    storedModel.value.selectedRoomTypes.length > 0
  )
})

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    Object.keys(newAmounts).forEach((roomType) => {
      updateRooms(roomType)
    })
  },
  { deep: true }
)

watch(storedModel.value.selectedRoomTypes, (newSelectedTypes) => {
  // Reset amount to 0 for deselected room types
  Object.keys(roomAmounts.value).forEach((roomType) => {
    if (!newSelectedTypes.includes(roomType)) {
      roomAmounts.value[roomType] = 0
      updateRooms(roomType)
    }
  })
})

watch(
  () => model.value.rooms,
  (newRooms) => {
    // Remove extra rooms from roomConfigs
    model.value.services.electrical.electrical_wiring.rooms =
      model.value.services.electrical.electrical_wiring.rooms.filter((roomId) =>
        newRooms.some((room) => room.id === roomId)
      )
  },
  { deep: true }
)

watch(
  () => model.value.services.electrical.electrical_wiring.enabled,
  (newValue) => {
    if (newValue) {
      model.value.services.electrical.switch_programm.enabled = newValue
    }
  }
)

onMounted(() => {
  const ignoredRoomIds = model.value.rooms
    .filter(
      (modelRoom) => !storedModel.value.rooms.some((storedRoom) => storedRoom.id === modelRoom.id)
    )
    .map((room) => room.id)

  // Update storedModel with the initial toIgnoreRooms
  storedModel.value.toIgnoreRooms = ignoredRoomIds

  if (storedModel.value.roomAmounts) {
    roomAmounts.value = storedModel.value.roomAmounts
  }

  roomTypes.forEach((type) => {
    if (roomAmounts.value[type.value] === undefined) {
      roomAmounts.value[type.value] = 0
    }
  })

  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'electric',
        content_name: 'electric'
      }
    ]
  })
  track.tmViewItem('electric')

  model.value.setup = true
  model.value.step = 'services_electrical_start'
  model.value.services.electrical.enabled = true
})
</script>

<style lang="postcss">
.small-qualities {
  img {
    @apply hidden;
  }

  @media (max-width: 1024px) {
    label {
      @apply ml-0 w-full pr-0 text-center;
    }

    [data-section='icon'] {
      @apply hidden;
    }
  }
}
</style>
