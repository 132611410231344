<template>
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-10">
        <Headline v-if="!model.step.includes('final')">{{ headlineText }}</Headline>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_heating_start'">
        <Headline>Soll eine neue Gas - Etagenheizung eingebaut werden?</Headline>
        <IconsOptionsSelection
          :options="yesNoString"
          cols="md:grid-cols-2"
          v-model="heatingSystem"
        />
        <SingleNavigation
          step="services_heating_geyser"
          prev="services_heating_start"
          v-model="model"
          :disabled="!heatingSystem"
        />
      </template>

      <template v-if="model.step === 'services_heating_system_house'">
        <Headline>Soll eine neue Heizungsanlage verbaut werden?</Headline>
        <IconsOptionsSelection
          :options="heatingOptions"
          cols="md:grid-cols-2"
          v-model="heatingOptionsComputed"
        />
        <SingleNavigation
          v-if="model.base.type === 'house'"
          step="services_heating_rebuild"
          prev="services_heating_start"
          v-model="model"
          :disabled="!heatingOptionsComputed"
          :deactivate-prev="true"
        />
        <SingleNavigation
          v-else
          step="services_heating_rebuild"
          v-model="model"
          :deactivatePrev="true"
          :disabled="!heatingOptionsComputed"
        />
      </template>

      <template v-if="model.step === 'services_heating_rebuild'">
        <Headline>Soll eine bestehende Heizungsanlage zurückgebaut werden?</Headline>
        <IconsOptionsSelection
          :options="rebuildOptions"
          cols="md:grid-cols-3"
          v-model="rebuildOptionsComputed"
        />
        <SingleNavigation
          v-if="model.base.type === 'house'"
          step="services_heating_geyser"
          prev="services_heating_system_house"
          v-model="model"
          :disabled="!rebuildOptionsComputed"
        />
        <SingleNavigation
          v-else
          step="services_heating_geyser"
          prev="services_heating_system_house"
          v-model="model"
          :disabled="!model.base.type"
        />
      </template>

      <template v-if="model.step === 'services_heating_geyser'">
        <Headline>Brauchen Sie einen Durchlauferhitzer?</Headline>
        <IconsOptionsSelection
          :options="yesNo"
          cols="md:grid-cols-2"
          v-model="geyserEnabledComputed"
        />
        <div class="mx-auto mt-14" v-if="geyserEnabledComputed">
          <div class="mb-4 flex flex-row items-center justify-between">
            <span class="text-xl font-semibold">Anzahl der Durchlauferhitzer:</span>
            <CounterInput v-model="model.services.heating.geyser.amount" />
          </div>
        </div>
        <SingleNavigation
          v-if="model.base.type === 'house'"
          step="services_heating_radiator"
          prev="services_heating_rebuild"
          v-model="model"
          :disabled="geyserEnabledComputed && !model.services.heating.geyser.amount"
        />
        <SingleNavigation
          v-else
          step="services_heating_radiator"
          prev="services_heating_start"
          v-model="model"
          :disabled="geyserEnabledComputed && !model.services.heating.geyser.amount"
        />
      </template>

      <template v-if="model.step === 'services_heating_radiator'">
        <Headline>Möchten Sie neue Heizkörper verbauen?</Headline>
        <IconsOptionsSelection
          name="radiator"
          :options="yesNo"
          cols="md:grid-cols-2"
          v-model="model.services.heating.radiator.enabled"
        />
        <SingleNavigation
          v-if="model.services.heating.radiator.enabled"
          step="services_heating_radiator_type"
          prev="services_heating_geyser"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="!model.services.heating.radiator.enabled && model.base.type === 'house'"
          step="services_heating_underfloor_heating"
          prev="services_heating_geyser"
          v-model="model"
        />
        <SingleNavigation
          v-else-if="!model.services.heating.radiator.enabled && model.base.type === 'apartment'"
          step="services_heating_stove"
          prev="services_heating_geyser"
          v-model="model"
        />
      </template>

      <template v-if="model.step === 'services_heating_radiator_type'">
        <Headline>Welche Heizkörper wünschen Sie sich?</Headline>
        <div class="mb-20">
          <IconsOptionsSelection
            name="radiator_type"
            :options="radiatorOptions"
            cols="md:grid-cols-2"
            class="mx-auto max-w-3xl"
            v-model="selectedRadiators"
            multiple
          >
            <template #option-slot="{ option, isActive }">
              <div v-if="isActive" class="mt-3 flex w-full flex-row items-center justify-between">
                <span>Anzahl:</span>
                <CounterInput
                  :modelValue="radiatorAmount.get(option.id)"
                  @update:modelValue="(newValue) => radiatorAmount.set(option.id, newValue)"
                />
              </div>
            </template>
          </IconsOptionsSelection>
          <SingleNavigation
            v-if="model.base.type === 'house'"
            step="services_heating_underfloor_heating"
            prev="services_heating_radiator"
            v-model="model"
            :disabled="!selectedRadiators.length"
          />
          <SingleNavigation
            v-else
            step="services_heating_stove"
            prev="services_heating_radiator"
            v-model="model"
            :disabled="!selectedRadiators.length"
          />
        </div>
      </template>

      <template v-if="model.step === 'services_heating_underfloor_heating'">
        <div class="flex flex-col gap-4">
          <Headline>Möchten Sie eine Fussbodenheizung verbauen?</Headline>
          <IconsOptionsSelection
            :options="yesNo"
            cols="md:grid-cols-2"
            v-model="underfloorHeatingComputed"
          />
          <div v-if="underfloorHeatingComputed">
            <div class="my-8 w-full">
              <Headline>Wieviel Quadratmeter Fussbodenheizung sollen verbaut werden?</Headline>
              <Range v-model="roomSize" :max="500" />
            </div>
          </div>
          <SingleNavigation
            v-if="!model.services.heating.radiator.enabled"
            step="services_heating_stove"
            prev="services_heating_radiator"
            v-model="model"
          />
          <SingleNavigation
            v-else
            step="services_heating_stove"
            prev="services_heating_radiator_type"
            v-model="model"
          />
        </div>
      </template>

      <template v-if="model.step === 'services_heating_stove'">
        <div class="flex flex-col gap-4">
          <Headline>Möchten Sie einen Ofen einbauen?</Headline>
          <IconsOptionsSelection
            :options="yesNo"
            cols="md:grid-cols-2"
            v-model="model.services.heating.stove.enabled"
          />
          <SingleNavigation
            v-if="navigationStep"
            :step="navigationStep.step"
            :prev="navigationStep.prev"
            v-model="model"
          />
        </div>
      </template>

      <template v-if="model.step === 'services_heating_stove_quality'">
        <div class="flex flex-col gap-4">
          <Headline>In welcher Qualität wünschen Sie sich Ihren Ofen?</Headline>
          <IconsOptionsSelection
            :options="qualities"
            cols="md:grid-cols-3"
            v-model="model.services.heating.stove.quality"
          />
          <SingleNavigation
            step="services_heating_chimney"
            prev="services_heating_stove"
            v-model="model"
            :disabled="!model.services.heating.stove.quality"
          />
        </div>
      </template>

      <template v-if="model.step === 'services_heating_chimney'">
        <div class="flex flex-col gap-4">
          <Headline>Möchten Sie einen Kamin einbauen?</Headline>
          <IconsOptionsSelection
            :options="yesNo"
            cols="md:grid-cols-2"
            v-model="model.services.heating.chimney.enabled"
          />
          <SingleNavigation
            v-if="model.services.heating.chimney.enabled && model.services.heating.stove.enabled"
            step="services_heating_chimney_quality"
            prev="services_heating_stove_quality"
            v-model="model"
          />
          <SingleNavigation
            v-if="!model.services.heating.chimney.enabled && model.services.heating.stove.enabled"
            step="services_heating_exhaust"
            prev="services_heating_stove_quality"
            v-model="model"
          />
          <SingleNavigation
            v-if="model.services.heating.chimney.enabled && !model.services.heating.stove.enabled"
            step="services_heating_chimney_quality"
            prev="services_heating_stove"
            v-model="model"
          />
          <SingleNavigation
            v-if="!model.services.heating.chimney.enabled && !model.services.heating.stove.enabled"
            step="services_heating_final"
            prev="services_heating_stove"
            v-model="model"
          />
        </div>
      </template>

      <template v-if="model.step === 'services_heating_chimney_quality'">
        <div class="flex flex-col gap-4">
          <Headline>In welcher Qualität wünschen Sie sich Ihren Kamin?</Headline>
          <IconsOptionsSelection
            :options="qualities"
            cols="md:grid-cols-3"
            v-model="model.services.heating.chimney.quality"
          />
          <SingleNavigation
            v-if="
              (model.services.heating.chimney.enabled || model.services.heating.stove.enabled) &&
              model.base.type === 'house'
            "
            step="services_heating_exhaust"
            prev="services_heating_chimney"
            v-model="model"
            :disabled="!model.services.heating.chimney.quality"
          />
          <SingleNavigation
            v-else-if="
              (model.services.heating.chimney.enabled || model.services.heating.stove.enabled) &&
              model.base.type === 'apartment'
            "
            step="services_heating_final"
            prev="services_heating_chimney"
            v-model="model"
            :disabled="!model.services.heating.chimney.quality"
          />
        </div>
      </template>

      <template v-if="model.step === 'services_heating_exhaust'">
        <div class="flex flex-col gap-4">
          <Headline>Soll ein Außenabzug verbaut werden?</Headline>
          <IconsOptionsSelection
            :options="yesNo"
            cols="md:grid-cols-2"
            v-model="model.services.heating.chimney.exhaust.enabled"
          />
          <div class="mt-14" v-if="model.services.heating.chimney.exhaust.enabled">
            <Headline>Wie viele Stockwerke hat Ihr Gebäude? </Headline>
            <div class="mb-4 flex flex-row items-center justify-between">
              <span class="text-xl font-semibold">Anzahl der Stockwerke:</span>
              <CounterInput v-model="model.services.heating.floors" />
            </div>
          </div>
          <SingleNavigation
            v-if="model.services.heating.chimney.enabled"
            step="services_heating_final"
            prev="services_heating_chimney_quality"
            v-model="model"
          />
          <SingleNavigation
            v-else
            step="services_heating_final"
            prev="services_heating_chimney"
            v-model="model"
          />
        </div>
      </template>
    </form>
  </section>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import Range from '@/components/Inputs/Singles/Range.vue'
import createRoom from '@/utils/room.js'
import { useLocalStorage } from '@vueuse/core'
import { qualities } from '@/data/options/qualities.js'
import track from '@/utils/tracking.js'
import {
  rebuildOptions,
  yesNo,
  yesNoString,
  heatingOptions,
  radiatorOptions
} from '@/data/options/v2/heating.js'
import { ContactValidationSchema } from '@/utils/validators.js'

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  const steps = [
    'services_heating_start',
    'services_heating_system_house',
    'services_heating_rebuild',
    'services_heating_geyser',
    'services_heating_radiator',
    'services_heating_radiator_type',
    'services_heating_underfloor_heating',
    'services_heating_stove',
    'services_heating_stove_quality',
    'services_heating_chimney',
    'services_heating_chimney_quality',
    'services_heating_exhaust',
    'services_heating_final'
  ]
  return (steps.indexOf(model.value.step) / (steps.length - 1)) * 100
})

const heatingSystemValue = ref('')

const heatingSystem = computed({
  get: () => {
    return model.value.services.heating.heating_system.enabled ? 'yes' : 'no'
  },
  set: (v) => {
    heatingSystemValue.value = v
    if (v === 'yes') {
      model.value.services.heating.heating_system.enabled = true
      model.value.services.heating.heating_system.type = 'gas'
    } else {
      model.value.services.heating.heating_system.enabled = false
      model.value.services.heating.heating_system.type = ''
    }
  }
})

const initialedRadiators = ref([])

const selectedRadiators = computed({
  get() {
    return initialedRadiators.value
  },
  set(v) {
    const previousValue = initialedRadiators.value
    initialedRadiators.value = v

    // Find the removed items
    const removedItems = previousValue.filter((item) => !v.includes(item))
    const addedItems = v.filter((item) => !previousValue.includes(item))

    // Update the amount for the added and removed items
    model.value.services.heating.radiator.items.forEach((item) => {
      if (addedItems.includes(item.value)) {
        item.amount = 1
      } else if (removedItems.includes(item.value)) {
        item.amount = 0
      }
    })
  }
})

const radiatorAmount = {
  get: (id) => {
    return model.value.services.heating.radiator.items.find((item) => item.id === id)?.amount || 0
  },
  set: (id, newAmount) => {
    const item = model.value.services.heating.radiator.items.find((item) => item.id === id)
    if (item) {
      item.amount = newAmount
    }
  }
}

const heatingOptionsValue = ref('')

const heatingOptionsComputed = computed({
  get() {
    return heatingOptionsValue.value
  },
  set(value) {
    if (value !== 'no') {
      heatingOptionsValue.value = value
      model.value.services.heating.heating_system.type = value
      model.value.services.heating.heating_system.enabled = true
    } else {
      heatingOptionsValue.value = value
      model.value.services.heating.heating_system.type = ''
      model.value.services.heating.heating_system.enabled = false
    }
  }
})

const rebuildOptionsValue = ref('')

const rebuildOptionsComputed = computed({
  get() {
    return rebuildOptionsValue.value
  },
  set(value) {
    if (value !== 'no') {
      rebuildOptionsValue.value = value
      model.value.services.heating.heating_rebuild.type = value
      model.value.services.heating.heating_rebuild.enabled = true
    } else {
      rebuildOptionsValue.value = value
      model.value.services.heating.heating_rebuild.type = ''
      model.value.services.heating.heating_rebuild.enabled = false
    }
  }
})

const geyserAmountsValue = ref()

const geyserEnabledComputed = computed({
  get() {
    return model.value.services.heating.geyser.enabled
  },
  set(value) {
    model.value.services.heating.geyser.enabled = value
  }
})

const underfloorHeatingComputed = computed({
  get() {
    return model.value.services.heating.underfloor_heating.enabled
  },
  set(value) {
    model.value.services.heating.underfloor_heating.enabled = value
  }
})

const roomSize = computed({
  get() {
    const foundRoom = model.value?.rooms?.find((r) => r.id === room.value?.id)
    return foundRoom?.areas?.floor ?? 0 // Return 0 or another default value if not found
  },
  set(value) {
    const foundRoom = model.value?.rooms?.find((r) => r.id === room.value?.id)
    if (foundRoom && foundRoom.areas) {
      foundRoom.areas.floor = value
    } else {
      console.warn('Unable to set room size: Room not found or invalid structure')
    }
  }
})

const navigationStep = computed(() => {
  const { type } = model.value.base
  const { radiator, stove } = model.value.services.heating

  if (type === 'house') {
    if (stove.enabled) {
      return {
        step: 'services_heating_stove_quality',
        prev: 'services_heating_underfloor_heating'
      }
    } else {
      return {
        step: 'services_heating_chimney',
        prev: 'services_heating_underfloor_heating'
      }
    }
  } else if (type === 'apartment') {
    if (radiator.enabled && stove.enabled) {
      return {
        step: 'services_heating_stove_quality',
        prev: 'services_heating_radiator_type'
      }
    } else if (!radiator.enabled && stove.enabled) {
      return {
        step: 'services_heating_stove_quality',
        prev: 'services_heating_radiator'
      }
    } else if (radiator.enabled && !stove.enabled) {
      return {
        step: 'services_heating_chimney',
        prev: 'services_heating_radiator_type'
      }
    } else if (!radiator.enabled && !stove.enabled) {
      return {
        step: 'services_heating_chimney',
        prev: 'services_heating_radiator'
      }
    }
  }
  return null
})

const floors = computed({
  get() {
    return model.value.base.floors.length
  },
  set(value) {
    for (let i = 0; i < value; i++) {}
  }
})

const room = useLocalStorage('form_heating_room', createRoom('Badezimer', 'bathroom', false, true))

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'heating',
              content_name: 'heating'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('heating', data.volume / 100, 'EUR', data.id, model.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}

watch(
  () => model.value.step,
  (newValue) => {
    if (model.value.base.type === 'house') {
      if (newValue === 'services_heating_start') {
        model.value.step = 'services_heating_system_house'
      }
    }
  }
)

onMounted(() => {
  if (model.value.base.type === 'house') {
    model.value.step = 'services_heating_system_house'
  } else {
    model.value.step = 'services_heating_start'
  }

  if (
    model.value.services.heating.heating_system.enabled &&
    model.value.services.heating.heating_system.type
  ) {
    heatingOptionsValue.value = model.value.services.heating.heating_system.type
  }

  if (
    model.value.services.heating.heating_rebuild.enabled &&
    model.value.services.heating.heating_rebuild.type
  ) {
    rebuildOptionsValue.value = model.value.services.heating.heating_rebuild.type
  }

  if (model.value.services.heating.geyser.enabled && model.value.services.heating.geyser.amount) {
    geyserAmountsValue.value = model.value.services.heating.geyser.amount
  }

  if (model.value.services.heating.radiator.items.length > 0) {
    initialedRadiators.value = model.value.services.heating.radiator.items
      .filter((item) => item.amount > 0)
      .map((item) => item.value)
  } else {
    model.value.services.heating.radiator.items = radiatorOptions.value.map((item) => ({
      id: item.id,
      label: item.value,
      value: item.label,
      amount: item.amount
    }))
  }

  if (!model.value.rooms.find((r) => r.id === room.value.id)) {
    model.value.rooms.push(room.value)
    model.value.services.heating.underfloor_heating.rooms.push(room.value.id)
  }
})
</script>
