<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    zoomAndPan="magnify"
    viewBox="0 0 375 374.999991"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    class="h-[1em] text-current"
  >
    <path
      fill="currentColor"
      d="M 369.460938 375 L 5.539062 375 C 2.480469 375 0 372.519531 0 369.460938 L 0 5.539062 C 0 2.480469 2.480469 0 5.539062 0 L 227.058594 0 C 230.117188 0 232.59375 2.480469 232.59375 5.539062 L 232.59375 134.492188 L 369.460938 134.496094 C 372.519531 134.496094 375 136.972656 375 140.03125 L 375 369.460938 C 375 372.519531 372.519531 375 369.460938 375 Z M 11.074219 363.925781 L 363.925781 363.925781 L 363.925781 145.570312 L 227.058594 145.570312 C 224 145.570312 221.519531 143.089844 221.519531 140.03125 L 221.519531 11.074219 L 11.074219 11.074219 Z M 11.074219 363.925781 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 306.171875 113.925781 C 304.730469 113.925781 303.316406 113.363281 302.253906 112.300781 L 270.609375 80.65625 C 268.445312 78.496094 268.445312 74.988281 270.609375 72.824219 C 272.773438 70.664062 276.277344 70.664062 278.441406 72.824219 L 300.632812 95.015625 L 300.632812 5.539062 C 300.632812 2.480469 303.113281 0 306.171875 0 C 309.230469 0 311.707031 2.480469 311.707031 5.539062 L 311.707031 108.386719 C 311.707031 110.625 310.359375 112.644531 308.289062 113.503906 C 307.605469 113.785156 306.886719 113.925781 306.171875 113.925781 Z M 306.171875 113.925781 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 306.171875 113.925781 C 304.753906 113.925781 303.335938 113.382812 302.253906 112.300781 C 300.09375 110.140625 300.09375 106.632812 302.253906 104.472656 L 333.898438 72.824219 C 336.0625 70.664062 339.570312 70.664062 341.734375 72.824219 C 343.894531 74.988281 343.894531 78.496094 341.734375 80.65625 L 310.085938 112.300781 C 309.003906 113.382812 307.585938 113.925781 306.171875 113.925781 Z M 306.171875 113.925781 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 132.121094 375 C 129.0625 375 126.582031 372.519531 126.582031 369.460938 L 126.582031 211.234375 C 126.582031 208.175781 129.0625 205.695312 132.121094 205.695312 C 135.179688 205.695312 137.660156 208.175781 137.660156 211.234375 L 137.660156 369.460938 C 137.660156 372.519531 135.179688 375 132.121094 375 Z M 132.121094 375 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 132.121094 74.367188 C 129.0625 74.367188 126.582031 71.886719 126.582031 68.828125 L 126.582031 5.539062 C 126.582031 2.480469 129.0625 0 132.121094 0 C 135.179688 0 137.660156 2.480469 137.660156 5.539062 L 137.660156 68.828125 C 137.660156 71.886719 135.179688 74.367188 132.121094 74.367188 Z M 132.121094 74.367188 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 163.765625 287.976562 L 132.121094 287.976562 C 129.0625 287.976562 126.582031 285.496094 126.582031 282.4375 C 126.582031 279.378906 129.0625 276.898438 132.121094 276.898438 L 163.765625 276.898438 C 166.824219 276.898438 169.304688 279.378906 169.304688 282.4375 C 169.304688 285.496094 166.824219 287.976562 163.765625 287.976562 Z M 163.765625 287.976562 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 37.183594 248.417969 L 5.539062 248.417969 C 2.480469 248.417969 0 245.9375 0 242.878906 C 0 239.820312 2.480469 237.339844 5.539062 237.339844 L 37.183594 237.339844 C 40.242188 237.339844 42.722656 239.820312 42.722656 242.878906 C 42.722656 245.9375 40.242188 248.417969 37.183594 248.417969 Z M 37.183594 248.417969 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 132.121094 335.441406 L 100.476562 335.441406 C 97.417969 335.441406 94.9375 332.964844 94.9375 329.90625 C 94.9375 326.847656 97.417969 324.367188 100.476562 324.367188 L 132.121094 324.367188 C 135.179688 324.367188 137.660156 326.847656 137.660156 329.90625 C 137.660156 332.964844 135.179688 335.441406 132.121094 335.441406 Z M 132.121094 335.441406 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      fill="currentColor"
      d="M 369.460938 311.707031 L 266.613281 311.707031 C 263.554688 311.707031 261.074219 309.230469 261.074219 306.171875 C 261.074219 303.113281 263.554688 300.632812 266.613281 300.632812 L 369.460938 300.632812 C 372.519531 300.632812 375 303.113281 375 306.171875 C 375 309.230469 372.519531 311.707031 369.460938 311.707031 Z M 369.460938 311.707031 "
      fill-opacity="1"
      fill-rule="nonzero"
    />
    <path
      stroke-linecap="butt"
      transform="matrix(0.53033, 0.53033, -0.53033, 0.53033, 227.918075, 134.030763)"
      fill="none"
      stroke-linejoin="miter"
      d="M 0.000358662 0.501427 L 2.18797 0.501427 "
      stroke="#001932"
      stroke-width="1"
      stroke-opacity="1"
      stroke-miterlimit="4"
    />
  </svg>
</template>
