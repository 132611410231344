<template>
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-10">
        <Headline>{{ headlineText }}</Headline>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_pipes_start'">
        <Headline>Wie viele Steigleitungen haben oder benötigen Sie?</Headline>
        <div class="mx-auto mt-14">
          <div class="mb-4 flex flex-row items-center justify-between">
            <span class="text-xl font-semibold">Anzahl der Steigleitungen:</span>
            <CounterInput v-model="model.services.pipes.renew.amount" />
          </div>
        </div>
        <SingleNavigation
          step="services_pipes_floors"
          v-model="model"
          :disabled="!model.services.pipes.renew.amount"
        />
      </template>

      <template v-if="model.step === 'services_pipes_floors'">
        <div class="jus flex flex-col justify-center">
          <Headline>Wie viele Stockwerke hat Ihr Gebäude</Headline>
          <div class="flex flex-row justify-center text-lg">
            <span>(Keller mit einbeziehen falls vorhanden)</span>
          </div>
        </div>

        <div class="mx-auto mt-14">
          <div class="mb-4 flex flex-row items-center justify-between">
            <span class="text-xl font-semibold">Anzahl der Stockwerke:</span>
            <CounterInput v-model="model.services.pipes.floors" />
          </div>
        </div>
        <SingleNavigation
          step="services_pipes_final"
          prev="services_pipes_start"
          v-model="model"
          :disabled="!model.services.pipes.floors"
        />
      </template>
    </form>
  </section>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import track from '@/utils/tracking.js'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import { ContactValidationSchema } from '@/utils/validators.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case '"services_pipes_start"':
      return 0
    case 'services_pipes_floors':
      return 50
    case 'services_pipes_final':
      return 100

    default:
      return 0
  }
})

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'pipes',
              content_name: 'pipes'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: formData.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('floor', data.volume / 100, 'EUR', data.id, formData.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}

const floorsCount = ref(0)
const baseFloorsComputed = computed({
  get: () => floorsCount.value,
  set: (newValue) => {
    const oldValue = floorsCount.value
    floorsCount.value = newValue

    if (newValue > oldValue) {
      // Add new floors
      for (let i = oldValue; i < newValue; i++) {
        model.value.base.floors.push('floor_1')
      }
    } else if (newValue < oldValue) {
      // Remove excess floors
      model.value.base.floors.splice(newValue)
    }
  }
})

onMounted(() => {
  model.value.step = 'services_pipes_start'
  model.value.services.pipes.enabled = true
  model.value.services.pipes.renew.enabled = true
})
</script>
