<template>
  <div>
    <div
      class="mx-auto max-w-xl rounded-3xl bg-primary p-5 text-white sm:p-10"
      id="success-element"
    >
      <h1 class="mb-3 text-3xl font-bold text-white">{{ title }}</h1>
      <div class="prose text-lg text-white">
        <p>{{ message }}</p>
        <div v-if="props.success">
          <p class="text-white">{{ feedbackQuestion }}</p>
          <TrustPilotStatic />
          <p>{{ thankYouMessage }}</p>
        </div>
      </div>
    </div>
    <div class="mt-8 flex flex-row items-center justify-center">
      <button
        @click="redirectToRoot"
        class="btn flex flex-row items-center justify-center gap-x-3 text-xl underline"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script setup>
import TrustPilotStatic from '@/components/Elements/Trustpilot/TrustpilotStatic.vue'

const props = defineProps({
  title: {
    type: String,
    default: 'Vielen Dank für Ihre Anfrage!'
  },
  message: {
    type: String,
    default: 'Wir werden uns kurzfristig bei Ihnen melden, um alles weitere zu besprechen.'
  },
  success: {
    type: Boolean,
    default: false
  },
  feedbackQuestion: {
    type: String,
    default: 'Wie hat Ihnen der Sanierungsrechner gefallen?'
  },
  thankYouMessage: {
    type: String,
    default: 'Vielen Dank für Ihr Vertrauen und viel Erfolg bei Ihrer anstehenden Sanierung.'
  },
  buttonText: {
    type: String,
    default: 'Zurück zur Startseite'
  }
})

const redirectToRoot = () => {
  window.location.href = '/'
}
</script>
