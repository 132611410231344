<template>
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-10">
        <Headline>{{ headlineText }}</Headline>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_windows_start'">
        <Headline>Was soll gemacht werden?</Headline>
        <IconsOptionsSelection
          :options="window_conf"
          cols="md:grid-cols-2"
          v-model="selectedWindowTypeComputed"
        />
        <SingleNavigation
          v-if="model.services.windows.change.type === 'renew'"
          step="services_windows_window_material"
          v-model="model"
          :disabled="!selectedWindowTypeComputed"
        />
        <SingleNavigation
          v-else
          step="services_windows_wood_lack"
          v-model="model"
          :disabled="!selectedWindowTypeComputed"
        />
      </template>

      <template
        v-else-if="
          model.step === 'services_windows_window_material' &&
          model.services.windows.change.type === 'renew'
        "
      >
        <Headline>Aus welchem Material sollen ihre Fenster sein?</Headline>
        <div class="mx-auto max-w-3xl">
          <IconsOptionsSelection
            :options="window_material"
            v-model="model.services.windows.change.material"
            cols="md:grid-cols-2"
          />
        </div>
        <SingleNavigation
          step="services_windows_window_amount"
          prev="services_windows_start"
          v-model="model"
          :disabled="!model.services.windows.change.material"
        />
      </template>

      <template
        v-else-if="
          model.step === 'services_windows_window_amount' &&
          model.services.windows.change.type === 'renew'
        "
      >
        <div class="mb-20">
          <Headline>Wie viele Fenster sollen erneuert werden?</Headline>
          <div class="mx-auto max-w-3xl">
            <IconsAmountSelection
              @change="onAmountChange"
              v-model="model.services.windows.change.amounts"
              :options="window_type"
            />
          </div>

          <SingleNavigation
            step="services_windows_renew_sills"
            prev="services_windows_window_material"
            v-model="model"
            @click="createWindows"
            :disabled="!windowsAmountCheck"
          />
        </div>
      </template>

      <template v-else-if="model.step === 'services_windows_renew_sills'">
        <Headline>Sollen die Fensterbänke auch erneuert werden?</Headline>
        <div class="mx-auto max-w-3xl">
          <IconsOptionsSelection
            :options="window_sills_options"
            v-model="windowSillsComputed"
            cols="md:grid-cols-4"
          />
        </div>
        <SingleNavigation
          step="services_windows_final"
          prev="services_windows_window_amount"
          v-model="model"
          @click="createWindows"
        />
      </template>

      <template
        v-else-if="
          model.step === 'services_windows_wood_lack' && model.services.windows.lack.enabled
        "
      >
        <Headline>Welche der bestehenden Holzfenster sollen lackiert werden?</Headline>
        <div class="mx-auto max-w-3xl">
          <IconsOptionsSelection
            :options="window_outer_inner"
            v-model="model.services.windows.lack.type"
            cols="md:grid-cols-3"
          />
          <div v-if="model.services.windows.lack.type">
            <Headline class="mt-12">Wie viele Fenster sollen lackiert werden?</Headline>
            <div class="mb-4 flex flex-row items-center justify-between">
              <span class="mr-1 text-lg font-semibold">Anzahl der Fenster:</span>
              <CounterInput v-model="model.services.windows.lack.amount" />
            </div>
          </div>
        </div>
        <SingleNavigation
          step="services_windows_final"
          prev="services_windows_start"
          v-model="model"
          :disabled="model.services.windows.lack.amount === 0"
        />
      </template>
    </form>
  </section>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import IconsAmountSelection from '@/components/Inputs/Singles/IconsAmountSelection.vue'
import { getGclid, getUtmParams } from '@/utils/gclid'
import {
  window_outer_inner,
  window_sills_options,
  window_conf,
  window_material,
  window_type
} from '@/data/options/v2/window.js'
import { ContactValidationSchema } from '@/utils/validators.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const steps = computed({
  get: () => props.steps,
  set: (v) => emit('update:steps', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case 'services_windows_start':
      return 0
    case 'services_windows_renew':
      return 15
    case 'services_windows_window_material':
      return 30
    case 'services_windows_window_amount':
      return 45
    case 'services_windows_renew_sills':
      return 60
    case 'services_windows_wood_lack':
      return 75
    case 'services_windows_final':
      return 100
    default:
      return 0
  }
})

const selectedWindowTypeComputed = computed({
  get: () => {
    if (model.value.services.windows.change.type === 'renew') {
      return 'change'
    } else if (model.value.services.windows.change.type === 'improve') {
      return 'lack'
    } else {
      return ''
    }
  },
  set: (value) => {
    switch (value) {
      case 'change':
        model.value.services.windows.change.enabled = true
        model.value.services.windows.lack.enabled = false
        model.value.services.windows.change.type = 'renew'
        break
      case 'lack':
        model.value.services.windows.change.enabled = true
        model.value.services.windows.lack.enabled = true
        model.value.services.windows.change.type = 'improve'
        break
      default:
        model.value.services.windows.change.enabled = false
        model.value.services.windows.lack.enabled = false
        break
    }
  }
})

const onAmountChange = ({ value, type }) => {
  if (value === undefined) {
    return
  }
}

const createWindows = () => {
  model.value.services.windows.change.configs = []
  const windowsAmounts = model.value.services.windows.change.amounts

  const windows = {
    singleLeaf: { type: 'singleLeaf', width: 0, height: 0, built: '' },
    doubleLeaf: { type: 'doubleLeaf', width: 0, height: 0, built: '' },
    balconyTerraceDoor: { type: 'balconyTerraceDoor', width: 0, height: 0, built: '' },
    largeFixed: { type: 'largeFixed', width: 0, height: 0, built: '' },
    tiltTurnSlide: { type: 'tiltTurnSlide', width: 0, height: 0, built: '' },
    small: { type: 'small', width: 0, height: 0, built: '' }
  }

  // Iterate through the window amounts
  for (const type in windowsAmounts) {
    const amount = windowsAmounts[type]
    if (amount > 0) {
      // Add the same object `amount` times to configs
      for (let i = 0; i < amount; i++) {
        model.value.services.windows.change.configs.push({ ...windows[type] })
      }
    }
  }
}

const windowSillsComputed = computed({
  get: () => {
    if (model.value.services.windows.renewSills.enabled) {
      return model.value.services.windows.renewSills.type
    }
    return false
  },
  set: (value) => {
    switch (value) {
      case 'inner':
        model.value.services.windows.renewSills.enabled = true
        model.value.services.windows.renewSills.type = 'inner'
        break
      case 'outer':
        model.value.services.windows.renewSills.enabled = true
        model.value.services.windows.renewSills.type = 'outer'
        break
      case 'both':
        model.value.services.windows.renewSills.enabled = true
        model.value.services.windows.renewSills.type = 'both'
        break
      case false:
        model.value.services.windows.renewSills.enabled = false
        break
      default:
        model.value.services.windows.change.material = ''
        break
    }
  }
})

const submitting = ref(false)
const submitted = ref(false)

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  model.value.contact.gclid = getGclid()
  model.value.utm = getUtmParams()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(props.modelValue)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'electric',
              content_name: 'electric'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: formData.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('window', data.volume / 100, 'EUR', data.id, formData.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}

const windowsAmountCheck = ref(false)

function updateWindowsAmountCheck(value) {
  const amounts = Object.values(value)
  const total = amounts.reduce((acc, curr) => acc + curr, 0)
  windowsAmountCheck.value = total > 0
}

watch(
  () => model.value.services.windows.change.amounts,
  (value) => {
    updateWindowsAmountCheck(value)
  },
  { deep: true }
)

onMounted(() => {
  updateWindowsAmountCheck(model.value.services.windows.change.amounts)

  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'windows',
        content_name: 'windows'
      }
    ]
  })
  track.tmViewItem('window')

  model.value.services.windows.enabled = true
  model.value.setup = true
  model.value.step = 'services_windows_start'
})
</script>

<style lang="postcss">
.small-qualities {
  img {
    @apply hidden;
  }

  @media (max-width: 1024px) {
    label {
      @apply ml-0 w-full pr-0 text-center;
    }

    [data-section='icon'] {
      @apply hidden;
    }
  }
}
</style>
