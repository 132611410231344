import { z } from 'zod'

import QualitySchema from '@/data/validations/types/quality.js'
import RoomsSchema from '@/data/validations/types/rooms.js'

export const ElectricalSchema = z.object({
  security_box: z.object({
    enabled: z.boolean()
  }),
  heat_pump: z.object({
    enabled: z.boolean()
  }),
  data_cabinet: z.object({
    enabled: z.boolean()
  }),
  electrical_wiring: RoomsSchema,
  switch_programm: QualitySchema,
  ceiling_burning_point_created: RoomsSchema,
  ceiling_burning_point_extended: RoomsSchema,
  dimmer: RoomsSchema
})

export default ElectricalSchema
