import { z } from 'zod'

export function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}

export const ContactValidationSchema = z.object({
  firstname: z.string().min(1),
  lastname: z.string().min(1),
  postal_code: z.number().min(1),
  email: z.string().email(),
  phone: z.string().min(1)
})
