import No from '@public/images/no.svg'
import Yes from '@public/images/yes.svg'
import LivingRoom from '@public/images/calc/rooms/livingroom.svg'
import Bedroom from '@public/images/calc/rooms/bedroom.svg'
import Floor from '@public/images/calc/rooms/flur.svg'
import Kitchen from '@public/images/calc/rooms/kitchen.svg'
import UtilityRoom from '@public/images/calc/rooms/utility_room.svg'
import Rebase from '@public/images/calc/walls/v2/rebase.svg'
import Paint from '@public/images/calc/walls/v2/paint.svg'
import Wallpapering from '@public/images/calc/walls/v2/wallpapering.svg'
import Tile from '@public/images/calc/walls/v2/tile.svg'
import Roof from '@public/images/calc/walls/v2/roof.svg'
import BudgetQuality from '@public/images/calc/quality/1_b.svg'
import StandardQuality from '@public/images/calc/quality/2_b.svg'
import PremiumQuality from '@public/images/calc/quality/3_b.svg'

export const roomTypes = [
  {
    label: 'Wohn-/Esszimmer',
    value: 'living_room',
    image: LivingRoom
  },
  {
    label: 'Schlaf-/Kinderzimmer',
    value: 'bedroom',
    image: Bedroom
  },
  {
    label: 'Flur',
    value: 'floor',
    image: Floor
  },
  {
    label: 'Küche',
    value: 'kitchen',
    image: Kitchen
  },
  {
    label: 'Hauswirtschaftsraum / Keller',
    value: 'utility_room',
    image: UtilityRoom
  }
]
export const yesNoString = [
  {
    label: 'Ja',
    value: 'yes',
    image: Yes
  },
  {
    label: 'Nein',
    value: 'no',
    image: No
  }
]

export const yesNo = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const wallsOptions = [
  {
    label: 'Wände spachteln, schleifen und streichen',
    image: Rebase,
    value: 'rebase'
  },
  {
    label: 'Wände nur streichen',
    image: Paint,
    value: 'paint'
  },
  {
    label: 'Wände tapezieren',
    image: Wallpapering,
    value: 'wallpaper'
  },
  { label: 'Wände fliesen', image: Tile, value: 'tile' }
]

export const wallsCeilingsOptions = [
  {
    label: 'Decke abhängen, spachteln, schleifen und streichen',
    image: Roof,
    value: 'all'
  },
  {
    label: 'Decke spachteln, schleifen und streichen',
    image: Roof,
    value: 'rebase'
  },
  {
    label: 'Decke nur streichen',
    image: Paint,
    value: 'painting'
  }
]

export const qualities = [
  {
    label: 'Budget - Qualität',
    image: BudgetQuality,
    value: 'budget'
  },
  {
    label: 'Standard - Qualität',
    image: StandardQuality,
    value: 'standard'
  },
  {
    label: 'Premium - Qualität',
    image: PremiumQuality,
    value: 'premium'
  }
]

export const configTemplate = {
  selectedRoomTypes: [],
  roomAmounts: {
    living_room: 0,
    kitchen: 0,
    bathroom: 0,
    utility_room: 0,
    cellar: 0,
    bedroom: 0
  },
  rooms: [],
  toIgnoreRooms: [],
  ceilingSpots: {
    roomConfigs: {}
  },
  change: {
    roomConfigs: {}
  },
  changeCeiling: {
    roomConfigs: {}
  },
  improveStucco: {
    rooms: []
  },
  newStucco: {
    rooms: []
  },
  removeOld: {
    rooms: []
  }
}
