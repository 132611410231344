import { z } from 'zod'

export const RoomsSchema = z
  .object({
    enabled: z.boolean(),
    rooms: z.array(z.string()).optional()
  })
  .refine(
    (value) => {
      return !(value.enabled && value.rooms.length === 0)
    },
    {
      message: 'rooms is required when enabled is true'
    }
  )

export default RoomsSchema
